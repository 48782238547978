import React from "react";
import { useNavigate } from "react-router-dom";


function BookingTable({ data }) {

  const navigate = useNavigate();

  const viewFollowUpForm = (e) => {
    navigate("/FollowUpForm", { state: e });
  };


  var getRecords = () => {
    var rows = data;
    return rows.map((record) => (
      <tr key={record.booking_id + "_" + record.sample_id}>
        <td data-label="BOOKING ID">{record.booking_id}</td>
        <td data-label="SAMPLE ID">{record.sample_id.toUpperCase()}</td>
        <td data-label="SAMPLE NO">{record.sample_no}/{record.samples_count}</td>
        <td data-label="FARM NAME">{record.farm_name.toUpperCase()}</td>
        <td data-label="FARM SIZE">{record.farm_size.toUpperCase()}&nbsp;{record.farm_size_unit.toUpperCase()}</td>
        <td className="d-block d-sm-block d-md-none d-lg-table-cell" data-label="PREVOIUS CROP">{record.previous_crop.toUpperCase()}</td>
        <td className="d-block d-sm-block d-md-none d-lg-table-cell" data-label="NEXT CROP">{record.next_crop.toUpperCase()}</td>
        <td data-label="COMMENTS">{record.comments ? record.comments.toUpperCase() : '-'}</td>
        <td data-label="TEST STATUS">{record.sample_status === 'S' ? 'SUBMITTED' : record.sample_status === 'I' ? 'IN-PROGRESS' : record.sample_status === 'C' ? 'COMPLETED' : ''}</td>
        <td data-label="DATE"> {new Date(Date.parse(record.created_at)).toDateString()} </td>
        {/* <td data-label="ACTIONS">
          {!record.follow_up_form_ind ?
            <button style={{ all: "unset", cursor: "pointer", color: "#113e21" }} title="Follow Up Question" onClick={() => { viewFollowUpForm(record.sample_id) }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 256 256">
                <rect width="256" height="256" fill="none" />
                <polyline points="144 56 216 128 144 200" fill="none" stroke="#113e21" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" />
              </svg>
            </button> :
            '-'
          }</td> */}
      </tr>
    ));
  };
  return (
    <table className={`table table-striped text-center table-borderless ${data.length > 1 ? 'align-middle' : ''}`}>
      <thead className="table-dark align-top">
        <tr>
          <th>BOOKING ID</th>
          <th>SAMPLE ID</th>
          <th>SAMPLE NO</th>
          <th>FARM NAME</th>
          <th>FARM SIZE</th>
          <th className="d-block d-sm-block d-md-none d-lg-table-cell">PREVIOUS CROP</th>
          <th className="d-block d-sm-block d-md-none d-lg-table-cell">NEXT CROP</th>
          <th>COMMENTS</th>
          <th>TEST STATUS</th>
          <th>DATE</th>
          {/* <th>ACTIONS</th> */}
        </tr>
      </thead>
      <tbody>
        {data.length > 0 ? (getRecords()) :
          (<tr><td className="no-entries" colSpan="10">No Entries Found..</td></tr>)}
      </tbody>
    </table>
  );
}

export default BookingTable;
