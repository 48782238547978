import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';

// import "./auth.css";

import { API_URL } from '../../../Globals';
import { schema } from './ProfileUpdateSchema';

import "bootstrap/dist/css/bootstrap.css";
import { Row, Col, Form } from "react-bootstrap";
import { Formik, Field, } from "formik";
import Select from "react-select";
import { State, City } from 'country-state-city';

function generateClassName(errors, touched, fieldName) {
  const hasError = errors[fieldName] && touched[fieldName];
  const isSuccess = touched[fieldName];
  return `form-control ${hasError ? 'border-2 border-danger' : isSuccess ? 'border-2 border-success' : ''}`;
}


function UpdateProfile(props) {

  const citySelectRef = useRef(null);
  const [stateCode, setStateCode] = useState("");

  const updatedStates = () =>
    State
      .getStatesOfCountry("IN")
      .map((state) => ({ key: state.id, label: state.name, value: state.id, ...state }));


  const updatedCities = (stateId) =>
    City
      .getCitiesOfState("IN", stateId)
      .map((city) => ({ label: city.name, value: city.id, ...city }));

  return (
    <Formik

      initialValues={
        {
          "email_id": props.userData.email_id,
          "address": props.userData.address,
          "pincode": props.userData.pincode,
          "city": props.userData.city,
          "state": props.userData.state,
        }}

      validationSchema={schema}

      const onSubmit={(values) => {
        values.Source = "USER";
        values.user_id = props.userData.user_id;
        var data = JSON.parse(JSON.stringify(values));

        axios
          .put(API_URL + 'user/details/' + data.user_id, {
            data,
            withCredentials: true
          })
          .then(function (res) {
            window.location.reload();
          })
          .catch(function (error) {
            if (error.response && error.response.status === 403) {
              alert("Token Expired Re-login");
              window.location.href = '/';
            }
            if (error.response && error.response.data.msg !== undefined)
              alert(error.response.data.msg);
            else {
              alert("Internal Server Error")
              window.location.href = '/';
            }
          });
      }}>

      {({ isSubmitting, values, handleChange, handleSubmit, setFieldValue, setFieldTouched, errors, touched }) => (

        <div className="p-2" contentEditable={false}>
          <h3 className="pt-4 pb-0">Update Info</h3>
          <Form className="form__details px-0" onSubmit={handleSubmit} onChange={handleChange}>
            <Row className="my-4">

              <Form.Group as={Col} md="12" className="group">
                <div className="form-floating">
                  <Field
                    type="email"
                    name="email_id"
                    value={values.email_id}
                    placeholder="Enter Email"
                    className={generateClassName(errors, touched, 'email_id')}
                    onChange={handleChange} />
                  <Form.Label htmlFor="email_id">Email</Form.Label>
                </div>
                {errors.email_id && touched.email_id ? (<div className="form_errors"> &#9888; {errors.email_id}</div>) : null}
              </Form.Group>

              <Form.Group as={Col} md="12" className="group">
                <div className="form-floating">
                  <Field
                    type="text"
                    name="address"
                    value={values.address}
                    placeholder="Enter Address"
                    className={generateClassName(errors, touched, 'address')}
                    onChange={handleChange} />
                  <Form.Label htmlFor="address">Address<span className="text-danger">&nbsp;*</span></Form.Label>
                </div>
                {errors.address && touched.address ? (<div className="form_errors"> &#9888; {errors.address}</div>) : null}
              </Form.Group>

              <Form.Group as={Col} md="12" className="group">
                <div className="form-floating">
                  <Field
                    type="number"
                    name="pincode"
                    value={values.pincode}
                    placeholder="Enter Pincode"
                    className={generateClassName(errors, touched, 'pincode')}
                    onChange={handleChange} />
                  <Form.Label htmlFor="pincode">Pincode<span className="text-danger">&nbsp;*</span></Form.Label>
                </div>
                {(errors.pincode && touched.pincode ? (<div className="form_errors"> &#9888; {errors.pincode}</div>) : null)}
              </Form.Group>

              <Form.Group as={Col} md="12" className="group">
                <Select
                  id="city"
                  name="city"
                  className={`custom-dropdown ${generateClassName(errors, touched, 'city')}`}
                  value={updatedCities(values.state && stateCode ? stateCode : null).find(option => option.label === values.city) || null}
                  ref={citySelectRef}
                  isSearchable={true}
                  placeholder="Select City"
                  options={updatedCities(values.state && stateCode ? stateCode : null)}
                  onChange={(value) => {
                    setFieldValue('city', value?.name);
                    values.city = value?.name;
                  }}
                  onBlur={() => setFieldTouched("city", true)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}

                />
                {errors.city && touched.city ? (<div className="form_errors"> &#9888; {errors.city}</div>) : null}
              </Form.Group>

              <Form.Group as={Col} md="12" className="group">
                <Select
                  id="state"
                  name="state"
                  className={`custom-dropdown ${generateClassName(errors, touched, 'state')}`}
                  defaultValue={values.state} value={updatedStates().find(option => {
                    if (option.label === values.state) {
                      setStateCode(option.isoCode);
                      return true;
                    }
                    return false;
                  })}
                  isSearchable={true}
                  placeholder="Select State"
                  options={updatedStates()}
                  onChange={(value) => {
                    values.state = value.name;
                    setFieldValue('state', value.name);
                    setStateCode(value.isoCode);
                    setFieldValue("city", "");
                    citySelectRef.current.clearValue();
                  }}
                  onBlur={() => setFieldTouched("state", true)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.state && touched.state ? (<div className="form_errors"> &#9888; {errors.state}</div>) : null}
              </Form.Group>
            </Row>

            <div className="my-3 col col-md-6 mx-auto">
              <button type="submit" className="w-100 text-center btn btn-dark" >Update</button>
            </div>
          </Form>
        </div>)
      }
    </Formik >


  );
}



export default UpdateProfile;

