import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_URL } from '../../../Globals';
import axios from 'axios';

import "./auth.css";
import "bootstrap/dist/css/bootstrap.css";
import image from '../../../assets/Images/LoginImage.png'
import SoilDoctorLogo from '../../../assets/Images/SoilDoctorLogo.png';
import Wrapper from '../../Common/Wrapper';

function PhoneInput(props) {

    const { value, handleChange } = props
    const [error, setMessage] = useState({
        error: '',
        success: ''
    });


    const [device, setDevice] = useState(null);
    const handleConnect = async () => {
        try {
            navigator.bluetooth.requestDevice({
                acceptAllDevices: true,
                optional_services: ['battery_service', '0000111e-0000-1000-8000-00805f9b34fb', '00001105-0000-1000-8000-00805f9b34fb'] // Required to access service later.
            })
                .then((device) => readCharacteristic(device, '00001115-0000-1000-8000-00805f9b34fb', 'battery_level'))
                .catch((error) => console.error('Error requesting device:', error));

            //   const options = {
            //     filters: [
            //       // Add any specific filters to target your device
            //       // For example, you can specify the name or the UUID of the Bluetooth device
            //     //   { name: 'M30s' },
            //       { services: ['battery_service'] },
            //     ],
            //   };

            //   const bluetoothDevice = await navigator.bluetooth.requestDevice({
            //     acceptAllDevices: true,
            //     optionalServices: ['battery_service'] // Required to access service later.
            //   });

            //   navigator.bluetooth.requestDevice({
            //     acceptAllDevices: true,
            //     services: ['battery_service'] // Required to access service later.
            //   })
            //   navigator.bluetooth.requestDevice(options)
            //   .then(device => { 
            //     setDevice(device);
            //     device.gatt.connect();
            //   })          
            //   .then(server => {
            //     // Getting Battery Service…
            //     return server.getPrimaryService('battery_service');
            //   })
            //   .then(service => {
            //     // Getting Battery Level Characteristic…
            //     return service.getCharacteristic('battery_level');
            //   })
            //   .then(characteristic => {
            //     // Reading Battery Level…
            //     // Set up event listener for when characteristic value changes.
            //     characteristic.addEventListener('characteristicvaluechanged',
            //                                     handleBatteryLevelChanged);
            //     // Reading Battery Level…
            //     return characteristic.readValue();
            //   })
            //   .then(value => {
            //     console.log(`Battery percentage is ${value.getUint8(0)}`);
            //   })
            //   .catch(error => { console.error(error); });

            //   setDevice(bluetoothDevice);
            //   console.log('Connected device:', bluetoothDevice);

            // Now you can use the 'bluetoothDevice' object to communicate with the device
            // For example, you can use the 'bluetoothDevice.gatt.connect()' method to establish a GATT connection with the device
        } catch (error) {
            console.error('Bluetooth connection error:', error);
        }
    };

    const connect = async () => {
        // Obtain configured instance.
        // let terminal = new BluetoothTerminal();

        // console.log("terminal",terminal);

        // // Override `receive` method to handle incoming data as you want.
        // terminal.receive = function (data) {
        //     alert(data);
        // };


        // // Request the device for connection and get its name after successful connection.
        // terminal.connect().then(() => {
        //     alert(terminal.getDeviceName() + ' is connected!');
        // });

        // // Send something to the connected device.
        // terminal.send('Simon says: Hello, world!');
        // console.log("Simon says: Hello, world!",terminal);

        // // Disconnect from the connected device.
        // terminal.disconnect();
    }

    async function readCharacteristic(device, serviceUuid, characteristicUuid) {
        try {
            // Connect to the device
            const server = await device.gatt.connect();
            console.log('Device connected:', device);
            setDevice(device);

            // // Discover the service
            // const service = await server.getPrimaryService(serviceUuid);
            // console.log('Service discovered:', service);

            // // Get the characteristic
            // const characteristic = await service.getCharacteristic(characteristicUuid);
            // console.log('Characteristic discovered:', characteristic);

            // // Read the characteristic value
            // const value = await characteristic.readValue();
            // // Process the value (e.g., convert to string, interpret as data, etc.)
            // console.log('Characteristic value:', value);
        } catch (error) {
            console.error('Error reading characteristic:', error);
        }
    }


    // function handleBatteryLevelChanged(event) {
    //     const batteryLevel = event.target.value.getUint8(0);
    //     console.log('Battery percentage is ' + batteryLevel);
    // }

    return (
        <Wrapper>
            <div className="login_container bg-darkgreen">
                <div className="center_div input_box bg-white p-4">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <a href="/">
                            <img src={SoilDoctorLogo} alt="Logo" className="img-fluid " width="250px" />
                        </a>
                    </div>
                    <div className="mt-4">
                        {device ? (
                            <div className="m-4">
                                <p><b>Connected Device:</b></p>
                                <p>Name: {device.name}</p>
                                <p>UUID: {device.id}</p>
                                {/* Display additional information about the connected device */}

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <button onClick={connect}>Change Bluetooth Device</button>
                                    <button type="button" onClick={() => { props.nextStep() }}>Proceed to Test</button>
                                </div>

                            </div>
                        ) :

                            <button onClick={connect}>Connect to Bluetooth Device</button>}
                    </div>
                </div>
            </div>
        </Wrapper>
    );

    // return (
    //     <div className="login_container bg-darkgreen">
    //         <div className="center_div input_box bg-white p-4">
    //             <div className="d-flex flex-wrap justify-content-between align-items-center">
    //                 <img src={SoilDoctorLogo} alt="Logo" className="img-fluid " width="250px" />

    //                 <div className="form-check form-switch d-flex justify-content-end admin-toggler px-4">
    //                     <Link to="/admin">
    //                         <input className="form-check-input me-2" type="checkbox" id="adminToggleSwitch" onChange={props.toggleForm} />
    //                     </Link>
    //                     <label className="form-check-label" htmlFor="adminToggleSwitch">Admin</label>
    //                 </div>
    //             </div>
    //             <div className="d-flex w-100 pt-4">
    //                 <div className="flex-grow-1">
    //                     <h3><b>Welcomeeeeeeeeeeeee</b></h3>
    //                     <p className="m-0 text-muted">Sign in now to get your Soil Analysis Report.</p>
    //                     <form>
    //                         <input
    //                             type="number"
    //                             name="phone"
    //                             value={value.phone}
    //                             required
    //                             max="9999999999"
    //                             onChange={handleChange('phone')}
    //                             placeholder="98XXXXXXXX"
    //                             className="mt-5 form-control" />

    //                         <div className="p-2 text-danger">{error.error}</div>
    //                         <button type="submit" onClick={Continue.bind()} className="w-100 my-4 rounded-3 text-center btn btn-dark">Send OTP</button>
    //                     </form>
    //                 </div>
    //                 <div className="login_image_box col-lg-6 col-md-6 col-sm-12">
    //                     <img src={image} alt="Background" />
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );
}

export default PhoneInput;

