import React from "react";
import {
  Row,
  Col,
  Container,
  Card,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import Wrapper from "../../Common/Wrapper";

const ContactForm = () => {
  return (
    <Wrapper>
      <Container fluid className="px-0 py-md-3">
        <Row xs={1} lg={2}>
          {/* Contact form */}
          <Col>
            <Card>
              <div className="">
                <h2>Choose your problem</h2>
                <select
                  className="form-select mt-4"
                  aria-label="Default select example"
                >
                  {/* <option selected>Open this select menu</option> */}
                  <option value="1">Unable to update profile</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <div className="mt-5">
                  <Form.Control
                    as="textarea"
                    placeholder="Explain your problem"
                    style={{ height: "150px" }}
                    aria-label="Explain your problem"
                  />
                </div>
                <div className="my-3 col col-md-6 mx-auto">
                  <button
                    type="submit"
                    className="w-100 text-center btn btn-dark"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Card>
          </Col>

          {/* Address info */}
          <Col>
            <Card>
              <div className="">
                <h5>
                  <strong>Email</strong>:
                  <small>
                    <a href="mailto: hello@soildoctor.in">
                      {" "}
                      hello@soildoctor.in
                    </a>
                  </small>
                </h5>
                <h5>
                  <strong>Phone</strong>:<small> +91 8180058006</small>
                </h5>
                <h5>
                  <strong>Address</strong>:
                  <small>
                    {" "}
                    Delhi Pharmaceutical Science and Research University, DPSRU
                    Innovation Incubation Foundation, 5th Floor, 503, New Delhi,
                    Delhi - 110017
                  </small>
                </h5>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default ContactForm;
