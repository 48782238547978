import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import './auth.css';

import { API_URL } from '../../../Globals';
import { schema } from '../../Common/Forms/UserRegSchema.jsx';
import image from '../../../assets/Images/LoginImage.png';
import SoilDoctorLogo from '../../../assets/Images/SoilDoctorLogo.png';

import 'bootstrap/dist/css/bootstrap.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Formik, Field } from 'formik';
import Select from 'react-select';
import { FaSpinner } from 'react-icons/fa';
import { Country, State, City } from 'country-state-city';
import { Spinner } from 'react-bootstrap';

function generateClassName(errors, touched, fieldName) {
  const hasError = errors[fieldName] && touched[fieldName];
  const isSuccess = touched[fieldName];
  return `form-control ${
    hasError
      ? 'border-2 border-danger'
      : isSuccess
      ? 'border-2 border-success'
      : ''
  }`;
}

function UserRegForm(props) {
  const [incorrectPincode, setIncorrectPincode] = useState('');
  const citySelectRef = useRef(null);
  const [stateCode, setStateCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const key = queryParameters.get('data');
    if (key != null) {
      // axios
      //     .post(API_URL + "partnerLogin", {
      //         "key": key
      //     })
      //     .then(function (res) {
      //         console.log("res", res);
      //         if (res.status === 200 && res.data.verified) {
      //             localStorage.clear();
      //             sessionStorage.setItem('info', JSON.stringify(res.data.info));
      //             window.location = '/'
      //         }
      //         else {
      //             localStorage.clear();
      //             window.location = '/'
      //         }
      //     })
      //     .catch((error) => {
      //         if (error.response.status === 401 || error.response.status === 500) {
      //             window.location = '/'
      //             alert(error.response.data.msg)
      //         }
      //         console.log(error);
      //     });
    }
  }, []);

  const updatedStates = (countryId) =>
    State.getStatesOfCountry('IN').map((state) => ({
      key: state.id,
      label: state.name,
      value: state.id,
      ...state,
    }));

  const updatedCities = (stateId) =>
    City.getCitiesOfState('IN', stateId).map((city) => ({
      label: city.name,
      value: city.id,
      ...city,
    }));

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        mobile_no: props.value.phone,
        email_id: '',
        address: '',
        pincode: '',
        city: '',
        state: '',
        profile_picture: '',
      }}
      validationSchema={schema}
      const
      onSubmit={(values) => {
        setIsLoading(true);
        values.Source = 'USER';
        var data = JSON.parse(JSON.stringify(values));
        console.log('data', data);
        // var path = 'user/'
        // if((props.route).includes('admin'))
        //     path = 'admin/'

        axios
          .post(API_URL + 'signup', {
            data,
            withCredentials: true,
          })
          .then(function (res) {
            setIsLoading(false);
            window.location.reload();
            // props.onAuthenticated(res.data.data);
          })
          .catch(function (error) {
            if (error.response && error.response.status === 403) {
              alert('Token Expired Re-login');
              window.location.href = '/';
            }
            if (error.response && error.response.data.msg !== undefined)
              alert(error.response.data.msg);
            else {
              alert('Internal Server Error');
              window.location.href = '/';
            }
          });
      }}
    >
      {({
        isSubmitting,
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
      }) => (
        <div
          className={`login-container m-0  ${
            window.innerWidth <= 768 ? 'py-4' : 'py-0'
          }`}
        >
          <div className='login-left'>
            <div className='logo'>
              <img src={SoilDoctorLogo} alt='Logo' width='250px' />
            </div>
            <div className='left-content'>
              <h1 className='fw-bolder mb-3'>Welcome to Soil Doctor! </h1>
              <img src={image} alt='Background' className='responsive-image' />
              <figure className='w-75'>
                <blockquote className='blockquote'>
                  <h3>
                    To forget how to dig the earth and to tend the soil is to
                    forget ourselvesssssssssssss.
                  </h3>
                </blockquote>
                <figcaption className='blockquote-footer'>
                  <cite title='Source Title'>Mohandas K. Gandhi</cite>
                </figcaption>
              </figure>
            </div>
          </div>
          <div className='login-right'>
            <div className='login-form' contentEditable={false}>
              <Form
                className='form__details'
                onSubmit={handleSubmit}
                onChange={handleChange}
              >
                <h3 className='pt-4 pb-0'>User Registration Form</h3>
                <span>Please fill in the below details to continue</span>
                <Row className='my-4'>
                  <Form.Group as={Col} md='6' className='group'>
                    <div className='form-floating'>
                      <Field
                        type='text'
                        name='first_name'
                        value={values.first_name}
                        placeholder='Enter First Name'
                        className={generateClassName(
                          errors,
                          touched,
                          'first_name'
                        )}
                        onChange={handleChange}
                      />
                      <Form.Label htmlFor='first_name'>
                        First Name<span className='text-danger'>&nbsp;*</span>
                      </Form.Label>
                    </div>
                    {errors.first_name && touched.first_name ? (
                      <div className='form_errors'>
                        {' '}
                        &#9888; {errors.first_name}
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md='6' className='group'>
                    <div className='form-floating'>
                      <Field
                        type='text'
                        name='last_name'
                        value={values.last_name}
                        placeholder='Enter Last Name'
                        className={generateClassName(
                          errors,
                          touched,
                          'last_name'
                        )}
                        onChange={handleChange}
                      />
                      <Form.Label htmlFor='last_name'>
                        Last Name<span className='text-danger'>&nbsp;*</span>
                      </Form.Label>
                    </div>
                    {errors.last_name && touched.last_name ? (
                      <div className='form_errors'>
                        {' '}
                        &#9888; {errors.last_name}
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md='6' className='group'>
                    <div className='form-floating'>
                      <Field
                        disabled
                        type='text'
                        name='mobile_no'
                        value={values.mobile_no}
                        placeholder='Enter Mobile Number'
                        className='form-control border-2 border-success'
                        onChange={handleChange}
                      />
                      <Form.Label htmlFor='mobile_no'>
                        Mobile Number
                        <span className='text-danger'>&nbsp;*</span>
                      </Form.Label>
                    </div>
                    {errors.mobile_no && touched.mobile_no ? (
                      <div className='form_errors'>
                        {' '}
                        &#9888; {errors.mobile_no}
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md='6' className='group'>
                    <div className='form-floating'>
                      <Field
                        type='email'
                        name='email_id'
                        value={values.email_id}
                        placeholder='Enter Email'
                        className={generateClassName(
                          errors,
                          touched,
                          'email_id'
                        )}
                        onChange={handleChange}
                      />
                      <Form.Label htmlFor='email_id'>Email</Form.Label>
                    </div>
                    {errors.email_id && touched.email_id ? (
                      <div className='form_errors'>
                        {' '}
                        &#9888; {errors.email_id}
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md='6' className='group'>
                    <div className='form-floating'>
                      <Field
                        type='text'
                        name='address'
                        value={values.address}
                        placeholder='Enter Address'
                        className={generateClassName(
                          errors,
                          touched,
                          'address'
                        )}
                        onChange={handleChange}
                      />
                      <Form.Label htmlFor='address'>
                        Address<span className='text-danger'>&nbsp;*</span>
                      </Form.Label>
                    </div>
                    {errors.address && touched.address ? (
                      <div className='form_errors'>
                        {' '}
                        &#9888; {errors.address}
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md='6' className='group'>
                    <div className='form-floating'>
                      <Field
                        type='number'
                        name='pincode'
                        value={values.pincode}
                        placeholder='Enter Pincode'
                        className={generateClassName(
                          errors,
                          touched,
                          'pincode'
                        )}
                        onChange={handleChange}
                      />
                      <Form.Label htmlFor='pincode'>
                        Pincode<span className='text-danger'>&nbsp;*</span>
                      </Form.Label>
                    </div>
                    {errors.pincode && touched.pincode ? (
                      <div className='form_errors'>
                        {' '}
                        &#9888; {errors.pincode}
                      </div>
                    ) : null}
                  </Form.Group>

                  {/* <Form.Group as={Col} md="6" className="group">
                                        <div className="form-floating">
                                            <Field
                                                type="number"
                                                name="pincode"
                                                value={values.pincode}
                                                placeholder="Enter Pincode"
                                                className={generateClassName(errors, touched, 'pincode')}
                                                onChange={async (e) => {
                                                    handleChange(e);
                                                    if (e.target.value.length == 6) {
                                                        setIsLoading(true)
                                                        await axios.get(`https://www.postpincode.in/api/getPostalArea.php?pincode=${e.target.value}`)
                                                            .then(function (res) {
                                                                var response = res.data[0];

                                                                if (response.Status === "Success") {
                                                                    var pincodeDetails = response.PostOffice[0];
                                                                    setFieldValue('district', pincodeDetails.District)
                                                                    setFieldValue('state', pincodeDetails.State)
                                                                    values.district = pincodeDetails.District;
                                                                    values.state = pincodeDetails.State;
                                                                    setIncorrectPincode("")
                                                                }
                                                                else {
                                                                    setFieldValue('pincode', "")
                                                                    values.pincode = "";
                                                                    setIncorrectPincode("Incorrect Pincode")
                                                                }
                                                                setIsLoading(false)
                                                            })
                                                            .catch(function (error) {
                                                                console.log("error", error);
                                                                setIsLoading(false)
                                                            });
                                                    }
                                                    else {
                                                        setIncorrectPincode("")
                                                        setFieldValue('district', "")
                                                        setFieldValue('state', "")
                                                        values.district = "";
                                                        values.state = "";
                                                    }
                                                }} />
                                            <Form.Label htmlFor="pincode">Pincode<span className="text-danger">&nbsp;*</span></Form.Label>
                                        </div>
                                        {incorrectPincode ? (<div className="form_errors"> &#9888; {incorrectPincode}</div>)
                                            : (errors.pincode && touched.pincode ? (<div className="form_errors"> &#9888; {errors.pincode}</div>) : null)}
                                    </Form.Group> */}

                  {/* <Form.Group as={Col} md="6" className="group">
                                        <div className="form-floating">
                                            <Field
                                                type="text"
                                                name="district"
                                                value={values.district}
                                                placeholder="Enter District"
                                                className={generateClassName(errors, touched, 'district')}
                                                onChange={handleChange} />
                                            <Form.Label htmlFor="district">District<span className="text-danger">&nbsp;*</span></Form.Label>
                                        </div>
                                        {errors.district && touched.district ? (<div className="form_errors"> &#9888; {errors.district}</div>) : null}
                                    </Form.Group> */}

                  <Form.Group as={Col} md='6' className='group'>
                    <Select
                      id='state'
                      name='state'
                      className={`custom-dropdown ${generateClassName(
                        errors,
                        touched,
                        'state'
                      )}`}
                      defaultValue={values.state}
                      isSearchable={true}
                      placeholder='Select State'
                      options={updatedStates('IN')}
                      onChange={(value) => {
                        values.state = value.name;
                        setFieldValue('state', value.name);
                        setStateCode(value.isoCode);
                        setFieldValue('city', '');
                        citySelectRef.current.clearValue();
                      }}
                      onBlur={() => setFieldTouched('state', true)}
                    />
                    {errors.state && touched.state ? (
                      <div className='form_errors'> &#9888; {errors.state}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} md='6' className='group'>
                    <Select
                      id='city'
                      name='city'
                      className={`custom-dropdown ${generateClassName(
                        errors,
                        touched,
                        'city'
                      )}`}
                      ref={citySelectRef}
                      isSearchable={true}
                      placeholder='Select City'
                      options={updatedCities(
                        values.state && stateCode ? stateCode : null
                      )}
                      onChange={(value) => {
                        setFieldValue('city', value?.name);
                        values.city = value?.name;
                      }}
                      onBlur={() => setFieldTouched('city', true)}
                    />
                    {errors.city && touched.city ? (
                      <div className='form_errors'> &#9888; {errors.city}</div>
                    ) : null}
                  </Form.Group>
                </Row>

                <div className='my-3 col col-md-6 mx-auto'>
                  <button
                    type='submit'
                    className='w-100 text-center btn btn-dark'
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    )}
                    <span>Submit</span>
                  </button>
                </div>
              </Form>
            </div>
          </div>

          {isLoading && (
            <div className='loading-overlay'>
              <FaSpinner className='loading-icon spin' />
            </div>
          )}
        </div>
      )}
    </Formik>
  );
}

export default UserRegForm;
