import { React } from 'react';
import SoilDoctorLogo from '../../../assets/Images/SoilDoctorLogo.png';
import FormikForm from '../../Common/Forms/ReportForm';
import './form.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from 'react-router-dom';
import Wrapper from '../../Common/Wrapper';

function ReportForm() {
  return (
    <Wrapper>
      <div className='form'></div>;
    </Wrapper>
  );
}

export default ReportForm;
