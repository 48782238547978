import * as Yup from "yup";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const schema = Yup.object({
//  name:              Yup.string().trim().required("Name is required").min(2, 'Too Short').max(25,'Too Long'),
//  mobile_no:          Yup.string().trim().required("Mobile No. is required").matches(phoneRegExp, 'Phone number is not valid').min(10, "Too short").max(10, "Too long"),
//  loc:               Yup.string().trim().required("Location is required").min(2, "Too short"),
 farm_name:          Yup.string().trim().required("Farm name is required").min(3, "Too short"),
 farm_size:          Yup.number().required("Farm size is required"),//.integer("Farm Size can't include a decimal point"),
 previous_crop:      Yup.string().trim().required("Previous Crop is required").min(2, "Too short"),
 collection_date:    Yup.date().required("Date is required"),
 sample_code:        Yup.string().trim().required("Sample Code is required").min(2, 'Too Short'),
 sample_type:        Yup.string().trim().required("Sample Type is required").min(2, 'Too Short'),
 
 ph:                Yup.number().required("pH is required").min(0,"pH should be within 0-14").max(14,"pH should be within 0-14"),
 ec:                Yup.number().required("EC is required").min(0,"EC should be within 0-5").max(5,"EC should be within 0-5"),
 oc:                Yup.number().required("OC is required").min(0,"OC should be within 0-3").max(3,"OC should be within 0-3"),
 
 nitrogen:          Yup.number().required("Nitrogen is required").min(0,"Nitrogen should be within 0-1000").max(1000,"Nitrogen should be within 0-1000"),
 phosphorus:        Yup.number().required("Phosphorus is required").min(0,"Phosphorus should be within 0-500").max(500,"Phosphorus should be within 0-500"),
 potassium:         Yup.number().required("Potassium is required").min(0,"Potassium should be within 0-1000").max(1000,"Potassium should be within 0-1000"),
 
 sulphur:           Yup.number().min(0,"Sulphur should be within 0-100").max(100,"Sulphur should be within 0-100"),
 magnesium:         Yup.number().min(0,"Magnesium should be within 0-1000").max(1000,"Boron should be within 0-1000"),
 calcium:           Yup.number().min(0,"Calcium should be within 0-4000").max(4000,"Calcium should be within 0-4000"),
 
 zinc:              Yup.number().min(0,"Zinc should be within 0-5").max(5,"Zinc should be within 0-5"),
 iron:              Yup.number().min(0,"Iron should be within 0-50").max(50,"Iron should be within 0-50"),
 manganese:         Yup.number().min(0,"Manganese should be within 0-50").max(50,"Manganese should be within 0-50"),
 copper:            Yup.number().min(0,"Copper should be within 0-10").max(10,"Copper should be within 0-10"),
 boron:             Yup.number().min(0,"Boron should be within 0-5").max(5,"Boron should be within 0-5"),
 
 aluminum:          Yup.number().min(0,"Aluminum cannot be negative"),
 chlorine:          Yup.number().min(0,"Chlorine cannot be negative"),
 molybdenum:        Yup.number().min(0,"Molybdenum cannot be negative"),
 nickle:            Yup.number().min(0,"Nickle cannot be negative"),
 
 microbial_count:    Yup.number().min(0,"Microbial Count should be within 0-1800").max(1800,"Microbial Count should be within 0-1800"),
 microbial_remarks:  Yup.string().trim().min(2, 'Too Short').max(10000,'Too Long'),

 water_report:      Yup.string().required("Please choose one"),
 
//waterform
 w_collection_date:     Yup.date().when("water_report",{
                                        is: "Yes",
                                        then: Yup.date().required("Date is required")}),
 w_sample_code:         Yup.string().when("water_report",{
                                        is: "Yes",
                                        then: Yup.string().required("Sample Code is required")}),
 w_sample_type:         Yup.string().when("water_report",{
                                        is: "Yes",
                                        then: Yup.string().required("Sample Type is required")}),
 w_nitrogen:           Yup.number().when("water_report",{
                                        is: "Yes",
                                        then: Yup.number().required("Nitrogen is required").min(0,"Nitrogen cannot be negative")}),
 w_phosphorus:         Yup.number().when("water_report",{
                                        is: "Yes",
                                        then: Yup.number().required("Phosphorus is required").min(0,"Phosphorus cannot be negative")}),
 w_potassium:          Yup.number().when("water_report",{
                                        is: "Yes",
                                        then: Yup.number().required("Potassium is required").min(0,"Potassium cannot be negative")})          
});
