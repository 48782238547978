import "./branding.css";
import company_logo from "../../../assets/Images/ekosight_logo.png";

function Branding() {
  return (
    <div className="branding pagebreak">
      {/* <div className="brand_info">
        <img className="brand__logo" alt="logo" src={logo} />
      </div> */}
      <div className="brand_section">
        <img className="company__logo" alt="logo" src={company_logo} />
        <p className="mb-2"> <b>EKOSIGHT TECHNOLOGY PRIVATE LIMITED</b></p>
        <p> Email ID: contact@ekosight.com</p>
        <p> Contact us: 8150085009</p>
        <p> HSR Layout, Sector 1, Bangalore</p>
        <p> Karnataka, India, 560102</p>
      </div>
    </div>
  );
}

export default Branding;
