import { React } from "react";
import { useLocation } from "react-router-dom";
import SoilDoctorLogo from '../../../assets/Images/SoilDoctorLogo.png';
import FormikForm from '../../Common/Forms/ReportForm';

import "./form.css";
import "bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";
import Wrapper from '../../Common/Wrapper';


function EditReport() {

  const { state } = useLocation();

  return (
    <Wrapper>

      <div className="form">
        {/* <div className="navbar bg-light px-3">
        <Link to="/"><img className="" alt="logo" src={SoilDoctorLogo} width="200px"/></Link>     
        <Link to="/"><button className="ml-auto">Go Back</button></Link>
        </div>
        
      <FormikForm data={state} route="/report"/> */}

      </div>
    </Wrapper>
  );
}

export default EditReport;
