import 'bootstrap/dist/css/bootstrap.css';
import { React, useRef, useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { HiChevronRight } from 'react-icons/hi2';
import { useReactToPrint } from 'react-to-print';

import logo from '../../../assets/Images/logo.png';
import './Report.css';
import BarGraph from './BarGraph';
import WaterReportTable from './TableData';
import Branding from '../Print/Branding';
import PrintTemplate from '../Print/PrintTemplate';
import Wrapper from '../Wrapper';
import RecommendationTable from './RecommendationTable';
import { Form } from 'react-bootstrap';

function change_header() {
  var headings = document.getElementsByClassName('header__heading');
  [].slice.call(headings).forEach(function (heading) {
    heading.innerHTML = 'Soil & Water Analysis Report';
  });
}

function SoilReport(data, isHindi, language) {
  return (
    <>
      {/* Second Section of report */}
      <div className='soil__info'>
        <div className='row'>
          <div className='col-4'>
            <span className='soil__text label_font_size'>
              {isHindi ? (
                <span>संग्रहण की तिथि:</span>
              ) : (
                <span>Date of collection: </span>
              )}
            </span>
            <p className='font_size'>
              {data?.collection_date
                ? new Date(data.collection_date).toLocaleDateString('sv-SE')
                : ''}
            </p>
          </div>
          <div className='col-4'>
            <span className='soil__text label_font_size'>
              {/* Sample Code: */}
              {isHindi ? <span> नमूना कोड:</span> : <span>Sample Code: </span>}
            </span>
            <p className='font_size'>{data?.sample_code}</p>
          </div>
        </div>
      </div>

      {/* Graphs Section */}
      {/* <GaugeChart data={data} /> */}

      {/* Remarks Section */}
      {data?.microbial_remarks && (
        <div className='remark'>
          <div className='label_font_size'>Microbial Remarks:</div>
          <div className='font_size'>{data?.microbial_remarks}</div>
        </div>
      )}

      {/* <h4 className='bar_label'>Bar Graph Representation</h4> */}
      <BarGraph data={data} isHindi={isHindi} language={language} />
    </>
  );
}

function WaterReport(data) {
  return (
    <Wrapper>
      <div className='soil__info'>
        <div className='row'>
          <div className='col-4'>
            <span className='soil__text label_font_size'>
              Date of Water Collection:
            </span>
            <p className='font_size'>
              {data?.w_collection_date
                ? new Date(data.w_collection_date).toLocaleDateString('sv-SE')
                : ''}
            </p>
          </div>
          <div className='col-4'>
            <span className='soil__text label_font_size'>
              Water Sample Code:
            </span>
            <p className='font_size'>{data?.w_sample_code}</p>
          </div>
          <div className='col-4'>
            <span className='soil__text label_font_size'>
              Water Sample Type:
            </span>
            <p className='font_size'>{data?.w_sample_type}</p>
          </div>
        </div>
      </div>

      <WaterReportTable data={data} />

      {data?.w_sampleRemarks && (
        <div className='remark'>
          <div className='label_font_size'>Water Sample Remarks:</div>
          <div className='font_size'>{data?.w_sample_remarks}</div>
        </div>
      )}
    </Wrapper>
  );
}

function Report(props) {
  const componentRef = useRef();
  const [data, setData] = useState(null);

  //Varibale for Displaying differnt reports
  const [waterReportExist, setwaterReportExist] = useState(false);
  const [heading, setHeading] = useState(false);
  const [soil, setSoil] = useState(true);
  const [water, setWater] = useState(false);
  const [selectedValue, setSelectedValue] = useState('SoilReport');
  const [isHindi, setIsHindi] = useState(false);
  const [language, setLanguage] = useState('english');

  //For Download button
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'SoilDoctor-Report',
  });

  //Fetching Data & Setting Header Dynamically
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const jsonData = await JSON.parse(props.data);

      setData(jsonData);

      if (jsonData.water_report === 'Yes') {
        setHeading('Soil & Water Analysis Report');
        setwaterReportExist(true);
      } else {
        setHeading('Soil Analysis Report');
        setwaterReportExist(false);
      }
    };
    fetchData();
  }, [props.data]);

  const handleRadioClick = (event) => {
    const { value } = event.target;
    setSelectedValue(value);

    if (value === 'SoilReport') {
      setSoil(true);
      setWater(false);
    } else if (value === 'WaterReport') {
      setSoil(false);
      setWater(true);
    } else if (value === 'Combined') {
      setSoil(true);
      setWater(true);
    }
  };

  return (
    <>
      {/* Language switch button */}
      {/* <div className='d-flex pt-4 justify-content-end'>
        <span className='pe-2'>Eng</span>
        <Form>
          <Form.Check
            type='switch'
            id='custom-switch'
            className=''
            onChange={() => setIsHindi(!isHindi)}
          />
        </Form>
        <span className='pe-2'>हिंदी</span>
      </div> */}

      {/* Select language */}
      <div className='d-flex pt-4 justify-content-end'>
        <Form>
          <Form.Check
            inline
            className='input'
            label='Eng'
            name='group1'
            type='radio'
            id={`inline-1`}
            checked={language === 'english'}
            onChange={() => setLanguage('english')}
          />
          <Form.Check
            inline
            label='हिंदी'
            name='group1'
            type='radio'
            id={`inline-2`}
            checked={language === 'hindi'}
            onChange={() => setLanguage('hindi')}
          />
          <Form.Check
            inline
            label='తెలుగు'
            name='group1'
            type='radio'
            id={`inline-3`}
            checked={language === 'telugu'}
            onChange={() => setLanguage('telugu')}
          />
        </Form>
      </div>

      <div
        className='report_sections pt-2'
        onLoad={() => {
          if (waterReportExist) {
            change_header();
          }
        }}
      >
        <div className='paper'>
          <div className='print' ref={componentRef}>
            <div className='report__header'>
              <div className='report__logo'>
                <img
                  className='header__logo'
                  src={logo}
                  alt='logo'
                  style={{ marginRight: '10px' }}
                />
                <div className='header__name label'>Soil Doctor</div>
              </div>
              <div className='header__heading label'>{heading}</div>
            </div>

            {/* First Section of report */}
            <div className='info'>
              <div className='row'>
                <div className='col-3'>
                  <span className='detail__section--label label_font_size'>
                    {language === 'english' && <span>Name: </span>}
                    {language === 'hindi' && <span>नाम: </span>}
                    {language === 'telugu' && <span>పేరు: </span>}
                  </span>
                  <p className='font_size'>{data?.name}</p>
                </div>
                <div className='col-3'>
                  <span className='detail__section--label label_font_size'>
                    {language === 'english' && <span>Mob. No. :</span>}
                    {language === 'hindi' && <span>फ़ोन नंबर:</span>}
                    {language === 'telugu' && <span>మొబైల్ నెం. </span>}
                  </span>
                  <p className='font_size'>{data?.mobile_no}</p>
                </div>
                <div className='col-3'>
                  <span className='detail__section--label label_font_size'>
                    {language === 'english' && <span>Location: </span>}
                    {language === 'hindi' && <span>जगह: </span>}
                    {language === 'telugu' && <span>స్థానం: </span>}
                  </span>
                  <p className='font_size'>{data?.loc}</p>
                </div>
                <div className='col-3'>
                  <span className='detail__section--label label_font_size'>
                    {language === 'english' && <span>Village: </span>}
                    {language === 'hindi' && <span>गाँव: </span>}
                    {language === 'telugu' && <span>గ్రామం: </span>}
                  </span>
                  {data?.village ? (
                    <p className='font_size'>{data?.village}</p>
                  ) : (
                    <p className='font_size'>NA</p>
                  )}
                </div>
              </div>

              <div className='row'>
                <div className='col-3'>
                  <span className='detail__section--label label_font_size'>
                    {language === 'english' && <span>Previous Crop: </span>}
                    {language === 'hindi' && <span>पिछली फसल: </span>}
                    {language === 'telugu' && <span>మునుపటి పంట: </span>}
                  </span>
                  <p className='font_size'>{data?.previous_crop}</p>
                </div>
                <div className='col-3'>
                  <span className='detail__section--label label_font_size'>
                    {language === 'english' && <span>Farm Size:: </span>}
                    {language === 'hindi' && <span>खेत का आकार: </span>}
                    {language === 'telugu' && <span>మునుపటి పంట: </span>}
                  </span>
                  <p className='font_size'>
                    {data?.farm_size}&nbsp;{data?.farm_size_unit}
                  </p>
                </div>
                <div className='col-3'>
                  <span className='detail__section--label label_font_size'>
                    {language === 'english' && <span>Farm Name: </span>}
                    {language === 'hindi' && <span>खेत का नाम: </span>}
                    {language === 'telugu' && <span>పొలం పేరు: </span>}
                  </span>
                  <p className='font_size'>{data?.farm_name}</p>
                </div>
                <div className='col-3'>
                  <span className='soil__text label_font_size'>
                    {language === 'english' && <span>Next Crop: </span>}
                    {language === 'hindi' && <span>अगली फसल: </span>}
                    {language === 'telugu' && <span>తదుపరి పంట: </span>}
                  </span>
                  <p className='font_size'>{data?.next_crop}</p>
                </div>
              </div>
            </div>

            {soil ? SoilReport(data, isHindi, language) : null}

            <PrintTemplate />

            {data && (
              <RecommendationTable
                data={data}
                isHindi={isHindi}
                language={language}
              />
            )}

            {/* For the purpose of branding in print doc (hidden from Main view)*/}
            <PrintTemplate />
            <Branding />

            <div className='note__section'>
              <ul className='font_size'>
                {/* 1st part */}
                <li>
                  {language === 'english' && (
                    <span>
                      Results relate only to the sample as received and
                      information provided by the client. Ekosight accepts no
                      responsibility or liability for the origin, traceability
                      or quality of samples prior to receipt by the laboratory,
                      either of which may compromise the quality or accuracy of
                      the results generated. No representation or warranty is
                      given by Ekosight, or any member or employee as to the
                      accuracy of any test method or test results.
                    </span>
                  )}
                  {language === 'hindi' && (
                    <span>
                      परिणाम केवल प्राप्त नमूने और ग्राहक द्वारा प्रदान की गई
                      जानकारी से संबंधित हैं। इकोसाइट प्रयोगशाला द्वारा प्राप्त
                      होने से पहले नमूनों की उत्पत्ति, पता लगाने की क्षमता या
                      गुणवत्ता के लिए कोई ज़िम्मेदारी या उत्तरदायित्व स्वीकार
                      नहीं करता है, जिनमें से कोई भी उत्पन्न परिणामों की
                      गुणवत्ता या सटीकता से समझौता कर सकता है। किसी भी परीक्षण
                      पद्धति या परीक्षण के परिणाम की सटीकता के बारे में एकोसाइट,
                      या किसी सदस्य या कर्मचारी द्वारा कोई प्रतिनिधित्व या
                      वारंटी नहीं दी जाती है
                    </span>
                  )}
                  {language === 'telugu' && (
                    <span>
                      ఫలితాలు కేవలం అందుకున్న నమూనాకు మరియు క్లయింట్ అందించిన
                      సమాచారానికి మాత్రమే సంబంధించినవి. నమూనాలను ప్రయోగశాలకు
                      అందించే వరకు వాటి మూలం, ట్రేసబిలిటీ లేదా నాణ్యత కోసం
                      ఎకోసైట్ ఎలాంటి బాధ్యత లేదా లాయబిలిటీని అంగీకరించదు, వీటిలో
                      ఏదైనా ఫలితాల నాణ్యత లేదా ఖచ్చితత్వాన్ని అపహాస్యం చేయవచ్చు.
                      ఎకోసైట్, లేదా ఏదైనా సభ్యుడు లేదా ఉద్యోగి పరీక్షా విధానం
                      లేదా పరీక్షా ఫలితాల ఖచ్చితత్వానికి ఎలాంటి ప్రతినిధిత్వం
                      లేదా హామీ ఇవ్వదు.
                    </span>
                  )}
                </li>
                <li>
                  {/* 2nd part */}
                  {language === 'english' && (
                    <span>
                      Any action you take upon the information on this report is
                      strictly at your own risk, Ekosight will not be liable for
                      the use of guidance in the report in respect of any
                      business losses, including without limitation loss of or
                      damage to profits, income, revenue, use, production,
                      anticipated savings, business, contracts, commercial
                      opportunities or goodwill. No part of this report may be
                      reproduced or use for commercial or public use without
                      permission in writing from Ekosight.
                    </span>
                  )}
                  {language === 'hindi' && (
                    <span>
                      इस रिपोर्ट में दी गई जानकारी पर आप जो भी कार्रवाई करते
                      हैं, वह पूरी तरह से आपके अपने जोखिम पर है। एकोसाइट इस
                      रिपोर्ट में दिए गए मार्गदर्शन के उपयोग के कारण किसी भी
                      व्यवसायिक हानि के लिए उत्तरदायी नहीं होगा, जिसमें लाभ, आय,
                      राजस्व, उपयोग, उत्पादन, अनुमानित बचत, व्यवसाय, अनुबंध,
                      व्यावसायिक अवसर या सद्भावना का नुकसान या क्षति शामिल है।
                      इस रिपोर्ट का कोई भी हिस्सा एकोसाइट से लिखित अनुमति के
                      बिना व्यावसायिक या सार्वजनिक उपयोग के लिए पुनरुत्पादित या
                      उपयोग नहीं किया जा सकता है।
                    </span>
                  )}
                  {language === 'telugu' && (
                    <span>
                      ఈ నివేదికలో ఉన్న సమాచారం మీద మీరు తీసుకునే ఏ చర్య అయినా
                      పూర్తిగా మీ సొంత ప్రమాదం మీద ఉంటుంది, ఈ నివేదికలోని
                      మార్గదర్శకతను ఉపయోగించడం వల్ల వచ్చిన వ్యాపార నష్టాలకు,
                      లాభాలు, ఆదాయం, ఆదాయం, ఉపయోగం, ఉత్పత్తి, ఆశించిన పొదుపులు,
                      వ్యాపారం, ఒప్పందాలు, వాణిజ్య అవకాశాలు లేదా మంచి బహుమతి
                      పట్ల Ekosight బాధ్యత వహించదు. ఈ నివేదికలోని ఏ భాగాన్ని
                      Ekosight నుండి లిఖితపూర్వక అనుమతి లేకుండా వాణిజ్య లేదా
                      ప్రజా ఉపయోగం కోసం పునర్ముద్రించరాదు లేదా ఉపయోగించరాదు.
                    </span>
                  )}
                </li>
              </ul>
            </div>

            {/* Footer */}
            <div className='report__footer nonprint_comp'>
              <div className='report__footer--grid d-flex justify-content-around'>
                <div>
                  <span className='soil__text'>Contact Us:</span>
                  +91 8150085009
                </div>
                <div>
                  <span className='soil__text'>Email Us:</span>
                  hello@soildoctor.in
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='report_button' onClick={handlePrint}>
          <button type='submit'>
            {isHindi ? <span>डाउनलोड</span> : <span>Download</span>}
            <HiChevronRight style={{ marginLeft: '20px' }} />
          </button>
        </div>
      </div>
    </>
  );
}

export default Report;
