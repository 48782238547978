import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { API_URL } from '../../../Globals';
import { Button } from 'react-bootstrap';
import { FaClipboardList, FaRegEye } from 'react-icons/fa';

function ReportTable({ data }) {
  const navigate = useNavigate();
  const { state } = useLocation();

  var getReportData = (e) => {
    axios
      .get(API_URL + 'user/report/' + e, {
        withCredentials: true,
      })
      .then(function (res) {
        navigate('/report', { state: JSON.stringify(res.data) });
      })
      .catch(function (error) {
        if (error.response && error.response.status === 403) {
          alert('Token Expired Re-login');
          window.location.href = '/';
        }
        if (error.response && error.response.data.msg !== undefined)
          alert(error.response.data.msg);
        else {
          alert('Internal Server Error');
          window.location.href = '/';
        }
      });
  };

  var getRecords = () => {
    var rows = data;
    return rows.map((record) => (
      <tr key={record.report_id}>
        <td data-label='REPORT ID'>{record.report_id}</td>
        <td data-label='SAMPLE ID'>{record.sample_id.toUpperCase()}</td>
        <td data-label='FARM NAME'>{record.farm_name.toUpperCase()}</td>
        <td data-label='FARM SIZE'>
          {record.farm_size.toUpperCase()}&nbsp;
          {record.farm_size_unit.toUpperCase()}
        </td>
        <td data-label='PREVOIUS CROP'>{record.previous_crop.toUpperCase()}</td>
        <td data-label='NEXT CROP'>{record.next_crop.toUpperCase()}</td>
        <td data-label='DATE'>
          {new Date(Date.parse(record.created_at)).toDateString()}
        </td>
        <td data-label='ACTION'>
          {/* <button
            style={{ all: 'unset', cursor: 'pointer', color: '#113e21' }}
            title='View'
            onClick={() => {
              getReportData(record.report_id);
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-eye-fill'
              viewBox='0 0 16 16'
            >
              <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
              <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
            </svg>
            View
          </button> */}
          <Button
            variant='success'
            onClick={() => getReportData(record.report_id)}
          >
            <div className='d-flex justify-center align-items-center'>
              <FaRegEye size={20} />
              &nbsp;View
            </div>
          </Button>
        </td>
        <td data-label='FOLLOW UP'>
          <Button
            variant='primary'
            // onClick={() => getReportData(record.report_id)}
            onClick={() => navigate('/FollowUpForm',{state:record.sample_id})}
          >
            <div className='d-flex justify-center align-items-center'>
              <FaClipboardList size={20} />
              &nbsp;continue
            </div>
          </Button>
        </td>
      </tr>
    ));
  };
  return (
    <table
      className={`table table-striped text-center table-borderless ${
        data.length > 1 ? 'align-middle' : ''
      }`}
    >
      <thead className='table-dark align-top'>
        <tr>
          <th>REPORT ID</th>
          <th>SAMPLE ID</th>
          <th>FARM NAME</th>
          <th>FARM SIZE</th>
          <th>PREVIOUS CROP</th>
          <th>NEXT CROP</th>
          <th>DATE</th>
          <th>ACTIONS</th>
          <th>FOLLOW UP</th>
        </tr>
      </thead>
      <tbody>
        {data.length > 0 ? (
          getRecords()
        ) : (
          <tr>
            <td className='no-entries' colSpan='8'>
              No Entries Found..
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default ReportTable;
