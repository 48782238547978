import * as Yup from 'yup';

export const schema = Yup.object().shape({
  samples: Yup.array().of(
    Yup.object().shape({
      farm_name: Yup.string().trim().required('Required').min(3, 'Too short'),
      farm_size: Yup.number()
        .required('Required')
        .min(0.01, 'Should be greater than 0'),
      next_crop: Yup.string().required('Required').min(2, 'Too Short'),
      previous_crop: Yup.string()
        .trim()
        .required('Required')
        .min(2, 'Too short'),
      sample_type: Yup.string().trim().required('Required').min(2, 'Too Short'),
      comments: Yup.string().trim().min(2, 'Too Short'),
    })
  ),
});
