import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { API_URL } from '../../../Globals';
import '../Print/PrintTemplate';

const RecommendationTable = (props) => {
  const [recommendation, setRecommendation] = useState({});
  const [multiRecommendation, setMultiRecommendation] = useState(null);
  const language = props.language;

  const getReportData = async () => {
    try {
      const { data } = await axios.get(
        `${API_URL}recommendation-report/${props.data.report_id}`
      );
      setRecommendation(data.recommendation.recommendations.en);
      setMultiRecommendation(data.recommendation.recommendations);
    } catch (error) {
      console.log('ERROR: ', error);
    }
  };

  useEffect(() => {
    getReportData();
  }, []);

  useEffect(() => {
    if (language === 'hindi' && multiRecommendation) {
      setRecommendation(multiRecommendation.hi);
    } else if (language === 'telugu' && multiRecommendation) {
      setRecommendation(multiRecommendation.tel);
    } else if (multiRecommendation) {
      setRecommendation(multiRecommendation.en);
    }
  }, [language]);

  // function to generate rows of recommendation reprot
  const generateRow = (parameter, index, language) => {
    console.log(
      'Parameter relative level: ',
      parameter.parameter_relative_level
    );

    if (!parameter.application) {
      return;
    }
    return (
      <>
        <tr key={`row1-${index}`}>
          <td rowSpan={2} className='fs-6 fw-bold text-capitalize'>
            {parameter.parameter}
          </td>

          <td
            rowSpan={2}
            className={`text-capitalize fw-bold ${
              parameter.parameter_relative_level === 'low' ||
              parameter.parameter_relative_level.toLowerCase() === 'acidic' ||
              parameter.parameter_relative_level === 'कम' ||
              parameter.parameter_relative_level === 'अम्लीय' ||
              parameter.parameter_relative_level === 'తక్కువ' ||
              parameter.parameter_relative_level === 'ਤੇਜ਼ਾਬ'
                ? 'text-danger'
                : parameter.parameter_relative_level === 'medium' ||
                  parameter.parameter_relative_level.toLowerCase() ===
                    'neutral' ||
                  parameter.parameter_relative_level === 'मध्यम' ||
                  parameter.parameter_relative_level === 'మధ్యస్థం' ||
                  parameter.parameter_relative_level === 'కేజీ/ఎకరా'
                ? 'text-success'
                : 'text-warning'
            }`}
          >
            {parameter.parameter_relative_level}
          </td>

          <td colSpan={2}>
            <div className='text-capitalize'>
              {parameter.quantity && parameter.quantity + ' ' + parameter.unit}
              <span className='text-lowercase'>
                {parameter.quantity && language === 'english' ? ' of ' : ' '}
              </span>
              {parameter.to_apply}
            </div>
          </td>
        </tr>
        <tr key={`row2-${index}`}>
          <td>
            <div>{parameter.application}</div>
          </td>
        </tr>
      </>
    );
  };

  return (
    <div className='d-flex justify-content-center mt-4 px-2 pagebreak_after'>
      {Object.keys(recommendation).length !== 0 ? (
        <Table
          className='mt-5 caption-top mb-5 text-center align-middle'
          bordered
        >
          <thead>
            <tr>
              <th colSpan={3} className='p-4 fs-4 text-success-emphasis'>
                {language === 'english' && <span>Recommendation Report</span>}
                {language === 'hindi' && <span>सलाह प्रतिवेदन </span>}
                {language === 'telugu' && <span>సిఫార్సు నివేదిక </span>}
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th className='fs-6'>
                {language === 'english' && <span>Nutrient</span>}
                {language === 'hindi' && <span>पुष्टिकर </span>}
                {language === 'telugu' && <span>పోషకాహారం </span>}
              </th>
              <th className='fs-6'>
                {language === 'english' && <span>Condition</span>}
                {language === 'hindi' && <span>स्थिति </span>}
                {language === 'telugu' && <span>పరిస్థితి</span>}
              </th>
              <th colSpan={2} className='fs-6'>
                {language === 'english' && <span>To Apply</span>}
                {language === 'hindi' && <span>लगाना </span>}
                {language === 'telugu' && <span>దరఖాస్తు</span>}
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.values(recommendation).map((parameter, index) =>
              generateRow(parameter, index, language)
            )}
          </tbody>
        </Table>
      ) : (
        <div>Recommendation not available for this crop!</div>
      )}
    </div>
  );
};

export default RecommendationTable;
