import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import { Row, Col, Container, Card } from 'react-bootstrap';

import './home.css';
import { API_URL } from '../../../Globals';
import UserContext from '../../../UserContext';
import BookingForm from '../../Common/Forms/BookingFormikForm';
import SampleReport from '../../../assets/Files/SampleReport.pdf';
import Wrapper from '../../Common/Wrapper';

axios.defaults.withCredentials = true;

function Home() {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [hasDevice, setDevice] = useState(true);
  const [userActivitySummary, setUserActivitySummary] = useState({});
  const [Records, setRecords] = useState([]);
  var info = JSON.parse(sessionStorage.getItem('info'));

  function generateTiles(value, text1, text2, link, buttonName) {
    return (
      <Col>
        <Card className='text-center'>
          <Card.Body>
            <table className='stats-table mb-2'>
              <tbody>
                <tr>
                  <td className='number' rowSpan='2'>
                    {value}
                  </td>
                  <td>
                    {text1}
                    <br /> {text2}
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              className='btn btn-dark w-75 mt-2'
              onClick={() => {
                navigate(link);
              }}
            >
              {buttonName}
            </button>
          </Card.Body>
        </Card>
      </Col>
    );
  }

  function generateTiles2(value, text1, text2, link, buttonName) {
    return (
      <Col>
        <Card className='text-center'>
          <Card.Body>
            <table className='stats-table mb-2'>
              <tbody>
                <tr>
                  <td className='number' rowSpan='2'>
                    {value}
                  </td>
                  <td>
                    {text1}
                    <br /> {text2}
                  </td>
                </tr>
              </tbody>
            </table>
            <a href={link} target='_blank' rel='noopener noreferrer'>
              <button className='btn btn-dark w-75 mt-2'>{buttonName}</button>
            </a>
          </Card.Body>
        </Card>
      </Col>
    );
  }

  useEffect(() => {
    if (info?.path === 'form') {
      info.path = '';
      sessionStorage.setItem('info', JSON.stringify(info));
      navigate('/testform');
      return;
    }
    axios
      .get(API_URL + 'user/home', {
        withCredentials: true,
      })
      .then(function (res) {
        setUserActivitySummary(res.data);
        if (res.data.has_device === 'Yes') setDevice(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403)
          alert('Token Expired Re-login');
        else alert('Internal Server Error');
        window.location.href = '/';
      });
  }, []);

  const BookingComponent = () => {
    return (
      <div className='card-wrapper'>
        <Card className='p-1 p-md-3'>
          <Card.Body>
            <h2 className='fw-bolder mb-md-5'>Book Sample Test</h2>
            <BookingForm home='home' />
          </Card.Body>
        </Card>
      </div>
    );
  };

  return (
    <Wrapper>
      <div className='homepage'>
        <Container fluid className='px-0 py-md-3'>
          <Row xs={1} className='g-2 gx-md-4'>
            <Col lg={8} className='px-0 order-1 order-md-0 '>
              <Row xs={1} className='g-2'>
                {/* Dashboard welocome card */}
                <Col md={12} className='mb-2'>
                  <Card className=''>
                    <Card.Body>
                      <div>
                        <h2 className='fw-bolder'>
                          Hi, {userActivitySummary?.name} &nbsp; &#128075;{' '}
                        </h2>
                        <h2 className='fw-bolder'>Welcome Back !</h2>
                      </div>
                      <br />
                      <div>
                        <p>
                          {' '}
                          Get your soil analyzed and achieve best out of it with
                          our proven testing methods.
                        </p>
                        <p>
                          {' '}
                          Have a look at one of the{' '}
                          <a
                            href={SampleReport}
                            target='_blank'
                            rel='noreferrer'
                            className=''
                          >
                            Sample Report
                          </a>{' '}
                          to know more about it.
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {/* Booking form for mobile devices */}
              <Col lg={4} className='booking-form mb-2 d-lg-none d-block'>
                {BookingComponent()}
              </Col>

              {/* Action cards */}
              <Row
                xs={1}
                xl={hasDevice ? 3 : 3}
                md={hasDevice ? 2 : 2}
                sm={hasDevice ? 2 : 2}
                className='g-2'
              >
                {generateTiles(
                  userActivitySummary?.bookings,
                  'Previous',
                  'Bookings',
                  '/bookings',
                  'Show'
                )}
                {generateTiles(
                  userActivitySummary?.reports,
                  'Previous',
                  'Reports',
                  '/reports',
                  'Show'
                )}
                {hasDevice &&
                  generateTiles(
                    '?',
                    'Do you have Ekosight',
                    'soil testing Device?',
                    '/sampletest',
                    'Continue'
                  )}
                {hasDevice &&
                  generateTiles2(
                    '?',
                    'How to test using',
                    'Ekosight Device?',
                    'https://www.soildoctor.in/soil-testing-guide',
                    'Guide'
                  )}
                {generateTiles2(
                  '?',
                  'How to pack &',
                  'parcel soil sample?',
                  'https://www.soildoctor.in/soil-sampling-guide',
                  'Guide'
                )}
                {generateTiles(
                  '!',
                  'hello@soildoctor.in',
                  '+918180058006',
                  '/contact',
                  'Contact'
                )}
              </Row>
            </Col>

            {/* Booking form for desktop  */}
            <Col md={4} className='booking-form d-lg-block d-none'>
              {BookingComponent()}
            </Col>
          </Row>
        </Container>
      </div>
    </Wrapper>
  );
}

export default Home;
