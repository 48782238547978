import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaSortUp, FaSortDown, FaCheck, FaTimes } from 'react-icons/fa';
import { AiOutlineSearch } from 'react-icons/ai';

import './Report.css';
import { API_URL } from '../../../Globals';
import Report from '../../Common/Reports/Report';
import ReportTable from './ReportTable';
import PaginationComponent from './PaginationComponent';
import SoilDoctorLogo from '../../../assets/Images/SoilDoctorLogo.png';
import Wrapper from '../../Common/Wrapper';

const Reports = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [Records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    // window.scrollTo(0, 0);
    axios
      .get(API_URL + 'user/reports', {
        withCredentials: true,
      })
      .then(function (res) {
        setRecords(res.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403)
          alert('Token Expired Re-login');
        else alert('Internal Server Error');
        window.location.href = '/';
      });
  }, [state, navigate]);

  const filteredData = Records.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === 'string' || typeof value === 'number') &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    console.log('newItemsPerPage', newItemsPerPage);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
    console.log('itemsPerPage', itemsPerPage);
  };

  const totalItems = filteredData.length;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Wrapper>
      <div className='reports-container py-4'>
        <div className='d-flex flex-column flex-md-row justify-content-between mb-3 w-100'>
          <h1 className='fw-bolder'>Reports</h1>
          <div className='d-flex justify-content-end align-items-center position-relative mt-3 m-md-0'>
            <input
              type='text'
              className='form-control form-control-sm pr-4 bg-light p-md-3 p-2 border-secondary'
              placeholder='Search'
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1);
              }}
            />
            <i
              className='position-absolute'
              style={{
                right: '10px',
                top: '50%',
                color: '#113e21',
                transform: 'translateY(-50%)',
              }}
            >
              <AiOutlineSearch />
            </i>
          </div>
        </div>
        <ReportTable data={currentItems} />
        <PaginationComponent
          currentPage={currentPage}
          totalPages={Math.ceil(totalItems / itemsPerPage)}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </div>
    </Wrapper>
  );
};

export default Reports;
