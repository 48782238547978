import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
    const navigate = useNavigate()
    return (
        <div className="d-flex align-items-center justify-content-center mt-5 pt-5">
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"> <span className="text-danger">Opps!</span> Page not found.</p>
                <p className="lead">
                    The page you&apos;re looking for doesn&apos;t exist.
                </p>
                <button className='btn btn-dark mt-3' onClick={() => navigate('/')}>Go Home</button>
            </div>
        </div>
    )
}

export default NotFound