import * as Yup from "yup";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const schema = Yup.object({
    first_name:         Yup.string().trim().required('First Name is required').min(2, 'Too Short').max(25, 'Too Long'),
    last_name:          Yup.string().trim().required('Last Name is required').min(2, 'Too Short').max(25, 'Too Long'),
    mobile_no:          Yup.string().trim().required('Mobile Number is required').matches(phoneRegExp, 'Phone number is not valid').min(10, "Too short").max(10, "Too long"),
    email_id:           Yup.string().trim().email('Invalid Email ID'),
    address:            Yup.string().trim().required('Address is required').min(5, 'Too Short'),
    pincode:            Yup.string().trim().required('Pincode is required').matches(/^[0-9]{6}$/, 'Invalid Pincode'),
    city:               Yup.string().required('City is required'),
    state:              Yup.string().trim().required('State is required'),
    profile_picture:    Yup.string().default('Default.jpg'),
})