import React from "react";
import axios from "axios";

import "../../../App.css"
import "./Home.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../Generic/Header';

axios.defaults.withCredentials = true;

function Home() {

    return (
        <div className="homepage">
            <div>
                <div className="main-container">
                  <Header />
                  <h5 className="px-3 pt-2">Hi Admin!  Welcome to Soil Doctor</h5>

                    {/* <div className="home_center_div p-6">
                      Hi Admin
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Home;
