import { React } from 'react';
import SoilDoctorLogo from '../../../assets/Images/SoilDoctorLogo.png';
import BookingFormikForm from '../../Common/Forms/BookingFormikForm';

import './form.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Wrapper from '../../Common/Wrapper';

function BookingForm() {
  var partnerInfo = sessionStorage.getItem('info');

  return (
    <Wrapper>
      <Row className='g-3'>
        {/* Form section */}
        <Col lg={8} className='r-5 order-2 order-lg-1'>
          <Card>
            <h2 className='fw-bolder'> Booking Form</h2>
            <BookingFormikForm route='/' info={partnerInfo} />
          </Card>
        </Col>
        {/* Instructions section */}
        <Col className='order-1 order-lg-2'>
          <div className='card-wrapper'>
            <Card>
              <h2 className='fw-bolder'> Soil Booking Instructions </h2>

              <div className='accordion accordion-flush' id='accordionFlushExample'>
                <div className='accordion-item bg-transparent'>
                  <h2 className='accordion-header '>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseOne'
                      aria-expanded='false'
                      aria-controls='flush-collapseOne'
                    >
                      <h5> Tools Required </h5>
                    </button>
                  </h2>
                  <div
                    id='flush-collapseOne'
                    className='accordion-collapse collapse'
                    data-bs-parent='#accordionFlushExample'
                  >
                    <div className='accordion-body'>
                      <p>
                        Before collecting soil samples, you will need to gather
                        certain materials and tools:
                      </p>
                      <ul>
                        <li> A soil probe, clean plastic bucket, trowel </li>
                        <li> Permanent markers </li>
                        <li>
                          Sample bags. Many soil testing laboratories will
                          provide wax-lined sample bags. In lieu of
                          laboratory-provided bags, consider using paper bags or
                          zip-top bags.
                        </li>
                        <li> Clipboard and paper or field notebook </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseTwo'
                      aria-expanded='false'
                      aria-controls='flush-collapseTwo'
                    >
                      <h5> Item 2</h5>
                    </button>
                  </h2>
                  <div
                    id='flush-collapseTwo'
                    className='accordion-collapse collapse'
                    data-bs-parent='#accordionFlushExample'
                  >
                    <div className='accordion-body'>
                      Placeholder content for this accordion, which is intended
                      to demonstrate the <code>.accordion-flush</code> class.
                      This is the second item's accordion body. Let's imagine
                      this being filled with some actual content.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseThree'
                      aria-expanded='false'
                      aria-controls='flush-collapseThree'
                    >
                      <h5> Item 3</h5>
                    </button>
                  </h2>
                  <div
                    id='flush-collapseThree'
                    className='accordion-collapse collapse'
                    data-bs-parent='#accordionFlushExample'
                  >
                    <div className='accordion-body'>
                      Placeholder content for this accordion, which is intended
                      to demonstrate the <code>.accordion-flush</code> class.
                      This is the third item's accordion body. Nothing more
                      exciting happening here in terms of content, but just
                      filling up the space to make it look, at least at first
                      glance, a bit more representative of how this would look
                      in a real-world application.
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
}

export default BookingForm;
