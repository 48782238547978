import React, { useEffect } from 'react';
import { useLocation, useNavigate, redirect } from 'react-router-dom';

import Report from '../../Common//Reports/Report';
import Wrapper from '../../Common/Wrapper';

const ViewReport = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (state === null) {
      // navigate('/');
      return redirect('/');
    }
  }, [state, navigate]);

  return (
    <Wrapper>
      <Report data={state} random={'hello'} />
    </Wrapper>
  );
};

export default ViewReport;
