import React from 'react';
import Header from './Navbar';
import { Outlet } from 'react-router-dom';

function Layout({ children }) {
  return (
    <div>
      <Header />
      <Outlet>
        <main className='px-md-4 px-3 py-2' style={{ minHeight: 'calc(100vh - 110px)' }}>
          {children}
        </main>
      </Outlet>
    </div>
  );
}

export default Layout;