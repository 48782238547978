import React from 'react';
import './barGraph.css';

function scale(value, min, max, new_min, new_max) {
  var new_value = ((value - min) / (max - min)) * (new_max - new_min) + new_min;
  return new_value;
}

function GenerateBar(parameterName, parameterValue_str, range) {
  if (!parameterValue_str) return null;
  else {
    var parameterValue = parseFloat(parameterValue_str);
    var scaledParameterValue = 0;
    if (parameterValue < range[1]) {
      scaledParameterValue = scale(
        parameterValue,
        range[0],
        range[1] - 0.01,
        0,
        33
      );
    } else if (parameterValue >= range[1] && parameterValue <= range[2]) {
      scaledParameterValue = scale(parameterValue, range[1], range[2], 33, 66);
    } else if (parameterValue > range[2]) {
      scaledParameterValue = scale(
        parameterValue,
        range[2] + 0.01,
        range[3],
        66,
        99
      );
    }

    if (scaledParameterValue >= 0) {
      return (
        <li
          className={`eFloat${
            scaledParameterValue < 33
              ? 'low'
              : scaledParameterValue >= 33 && scaledParameterValue <= 66
              ? 'sufficient'
              : scaledParameterValue > 66
              ? 'high'
              : ' '
          }`}
          style={{ width: `${scaledParameterValue + '%'}`, maxWidth: '100%' }}
        >
          <span className='chart__label'>{parameterValue} </span>
        </li>
      );
    }
  }
  return null;
}

function BarGraph(props) {
  const data = props.data;
  const isHindi = props.isHindi;
  const language = props.language;
  return (
    <>
      <div className='bar_report'>
        <table>
          <tbody>
            {/* PHYSICAL */}
            <tr>
              <td>
                <div className='label fs-4 pt-2'>
                  {language === 'english' && <span>Physical: </span>}
                  {language === 'hindi' && <span>भौतिक: </span>}
                  {language === 'telugu' && <span>భౌతిక: </span>}
                </div>
              </td>
            </tr>

            <tr>
              <td className='col_1 align-items-center'>
                <ul className='graph_lables'>
                  {data?.ph && (
                    <li
                      className='fs-6 fw-bold'
                      style={{
                        paddingTop: '40px',
                        height: '80px',
                      }}
                    >
                      {language === 'english' && <span>pH </span>}
                      {language === 'hindi' && <span>पीएच </span>}
                      {language === 'telugu' && <span>పిహెచ్ / pH </span>}
                      <p className='range fw-bold'>6.5 - 7.5 </p>
                    </li>
                  )}
                  {data?.ec && (
                    <li
                      className='fs-6 fw-bold'
                      style={{
                        paddingTop: '30px',
                        height: '80px',
                      }}
                    >
                      {language === 'english' && <span>EC </span>}
                      {language === 'hindi' && <span>विद्युत चालकता</span>}
                      {language === 'telugu' && (
                        <span>ఎలక్ట్రికల్ కండక్టివిటీ / EC</span>
                      )}
                      <p className='range fs-6 fw-bold'>1-3 (ds/m)</p>
                    </li>
                  )}
                  {data?.oc && (
                    <li
                      className='fs-6 fw-bold'
                      style={{
                        paddingTop: '20px',
                        height: '80px',
                      }}
                    >
                      {language === 'english' && <span>OC</span>}
                      {language === 'hindi' && <span>जैविक कार्बन</span>}
                      {language === 'telugu' && (
                        <span>సేంద్రీయ కార్బన్ / OC</span>
                      )}
                      <p className='range fs-6 fw-bold'>0.5-0.75 (%)</p>
                    </li>
                  )}
                </ul>
              </td>
              <td className='col_2'>
                <ul className='chart--horizon'>
                  {GenerateBar(
                    'pH',
                    Number(data?.ph).toFixed(2),
                    [0, 6.5, 7.5, 14]
                  )}
                  {GenerateBar('EC', Number(data?.ec).toFixed(2), [0, 1, 3, 5])}
                  {GenerateBar(
                    'OC',
                    Number(data?.oc).toFixed(2),
                    [0, 0.5, 0.75, 3]
                  )}
                </ul>
              </td>
            </tr>

            {/* primary macro */}
            <tr>
              <td>
                <div className='label fs-4 pt-5'>
                  {language === 'english' && <span>Primary Macro: </span>}
                  {language === 'hindi' && <span>प्राथमिक मैक्रो: </span>}
                  {language === 'telugu' && (
                    <span>ప్రాథమిక మాక్రోన్యూట్రియెంట్: </span>
                  )}
                </div>
              </td>
            </tr>

            <tr>
              <td className='col_1'>
                <ul className='graph_lables'>
                  {data?.nitrogen && (
                    <li
                      className='fs-6 fw-bold'
                      style={{
                        height: '80px',
                        paddingTop: '40px',
                      }}
                    >
                      {language === 'english' && <span>Nitrogen</span>}
                      {language === 'hindi' && <span>नाइट्रोजन</span>}
                      {language === 'telugu' && (
                        <span>నైట్రోజన్ / Nitrogen</span>
                      )}
                      {language === 'telugu' ? (
                        <p className='range'>
                          280-560 (కిలోగ్రాములు/హెక్టారుకు)
                        </p>
                      ) : (
                        <p className='range'>280-560 (kg/ha)</p>
                      )}
                    </li>
                  )}
                  {data?.phosphorus && (
                    <li
                      className='fs-6 fw-bold'
                      style={{
                        height: '80px',
                        paddingTop: '30px',
                      }}
                    >
                      {language === 'english' && <span>Phosphorus</span>}
                      {language === 'hindi' && <span>फास्फोरस</span>}
                      {language === 'telugu' && (
                        <span>ఫాస్పరస్ / Phosphorus</span>
                      )}

                      {language === 'telugu' ? (
                        <p className='range'>25-50 (కిలోగ్రాములు/హెక్టారుకు)</p>
                      ) : (
                        <p className='range'>25-50 (kg/ha)</p>
                      )}
                    </li>
                  )}
                  {data?.potassium && (
                    <li
                      className='fs-6 fw-bold'
                      style={{
                        height: '80px',
                        paddingTop: '20px',
                      }}
                    >
                      {language === 'english' && <span>Potassium</span>}
                      {language === 'hindi' && <span>पोटैशियम</span>}
                      {language === 'telugu' && (
                        <span>పొటాషియం / Potassium </span>
                      )}

                      {language === 'telugu' ? (
                        <p className='range'>
                          110-280 (కిలోగ్రాములు/హెక్టారుకు)
                        </p>
                      ) : (
                        <p className='range'>110-280 (kg/ha)</p>
                      )}
                    </li>
                  )}
                </ul>
              </td>
              <td className='col_2'>
                <ul className='chart--horizon'>
                  {GenerateBar(
                    'Nitrogen',
                    Number(data?.nitrogen).toFixed(2),
                    [0, 280, 560, 1000]
                  )}
                  {GenerateBar(
                    'Phosphorus',
                    Number(data?.phosphorus).toFixed(2),
                    [0, 25, 50, 500]
                  )}
                  {GenerateBar(
                    'Potassium',
                    Number(data?.potassium).toFixed(2),
                    [0, 110, 280, 1000]
                  )}
                </ul>
              </td>
            </tr>

            {/* secondry macro  */}
            {(data?.sulphur || data?.magnesium || data?.calcium) && (
              <>
                <tr>
                  <td>
                    <div className='label fs-4 pt-5 secondary-macro pagebreak'>
                      <p className='one-line'>
                        {language === 'english' && (
                          <span>Secondary Macro: </span>
                        )}
                        {language === 'hindi' && <span>द्वितीयक मैक्रो: </span>}
                        {language === 'telugu' && <span>సెకండరీ మాక్రో: </span>}
                      </p>
                    </div>
                  </td>
                </tr>
              </>
            )}

            <tr>
              <td className='col_1'>
                <ul className='graph_lables'>
                  {data?.sulphur && (
                    <li
                      className='fs-6 fw-bold'
                      style={{
                        paddingTop: '40px',
                        height: '80px',
                      }}
                    >
                      {language === 'english' && <span>Sulphur</span>}
                      {language === 'hindi' && <span>गंधक</span>}
                      {language === 'telugu' && <span>సల్ఫర్ / Sulphur</span>}
                      {language === 'telugu' ? (
                        <p className='range'>
                          10-20 (మిల్లీగ్రాములు/కిలోగ్రాముకు)
                        </p>
                      ) : (
                        <p className='range'>10-20 (mg/kg)</p>
                      )}
                    </li>
                  )}
                  {data?.magnesium && (
                    <li
                      className='fs-6 fw-bold'
                      style={{
                        paddingTop: '30px',
                        height: '80px',
                      }}
                    >
                      {language === 'english' && <span>Magnesium</span>}
                      {language === 'hindi' && <span>मैगनीशियम</span>}
                      {language === 'telugu' && (
                        <span>మాగ్నీషియం / Magnesium</span>
                      )}
                      {language === 'telugu' ? (
                        <p className='range'>
                          100-200 (మిల్లీగ్రాములు/కిలోగ్రాముకు)
                        </p>
                      ) : (
                        <p className='range'>100-200 (mg/kg)</p>
                      )}
                    </li>
                  )}
                  {data?.calcium && (
                    <li
                      className='fs-6 fw-bold'
                      style={{
                        paddingTop: '20px',
                        height: '80px',
                      }}
                    >
                      {language === 'english' && <span>Calcium</span>}
                      {language === 'hindi' && <span>कैल्शियम</span>}
                      {language === 'telugu' && <span>కాల్షియం / Calcium</span>}

                      {language === 'telugu' ? (
                        <p className='range'>1400-2000 (పీపిఎమ్)</p>
                      ) : (
                        <p className='range'>1400-2000 (ppm)</p>
                      )}
                    </li>
                  )}
                </ul>
              </td>
              <td className='col_2'>
                <ul className='chart--horizon'>
                  {GenerateBar('Sulphur', data?.sulphur, [0, 10, 20, 100])}
                  {GenerateBar(
                    'Magnesium',
                    data?.magnesium,
                    [0, 100, 200, 1000]
                  )}
                  {GenerateBar('Calcium', data?.calcium, [0, 1400, 2000, 4000])}
                </ul>
              </td>
            </tr>

            {/* Micro */}
            {(data?.zinc ||
              data?.manganese ||
              data?.iron ||
              data?.copper ||
              data?.boron) && (
              <tr>
                <td>
                  <div className='label fs-4 pt-5'>
                    {language === 'english' && <span>Micro: </span>}
                    {language === 'hindi' && <span>सूक्ष्म: </span>}
                    {language === 'telugu' && <span>సూక్ష్మ: </span>}
                  </div>
                </td>
              </tr>
            )}

            <tr>
              <td className='col_1'>
                <ul className='graph_lables'>
                  {data?.zinc && (
                    <li
                      className='fs-6 fw-bold'
                      style={{
                        paddingTop: '40px',
                        height: '80px',
                      }}
                    >
                      {language === 'english' && <span>Zinc</span>}
                      {language === 'hindi' && <span>जस्ता</span>}
                      {language === 'telugu' && <span>జింక్ / Zinc</span>}

                      {language === 'telugu' ? (
                        <p className='range'>
                          0.5-1.0 (మిల్లీగ్రాములు/కిలోగ్రాముకు)
                        </p>
                      ) : (
                        <p className='range'>0.5-1.0 (mg/kg)</p>
                      )}
                    </li>
                  )}
                  {data?.iron && (
                    <li
                      className='fs-6 fw-bold'
                      style={{
                        paddingTop: '30px',
                        height: '80px',
                      }}
                    >
                      {language === 'english' && <span>Iron</span>}
                      {language === 'hindi' && <span>लोहा</span>}
                      {language === 'telugu' && <span>ఇనుము / Iron </span>}
                      {language === 'telugu' ? (
                        <p className='range'>
                          5-10 (మిల్లీగ్రాములు/కిలోగ్రాముకు)
                        </p>
                      ) : (
                        <p className='range'>5-10 (mg/kg)</p>
                      )}
                    </li>
                  )}
                  {data?.manganese && (
                    <li
                      className='fs-6 fw-bold'
                      style={{
                        paddingTop: '20px',
                        height: '80px',
                      }}
                    >
                      {language === 'english' && <span>Manganese</span>}
                      {language === 'hindi' && <span>मैंगनीज</span>}
                      {language === 'telugu' && <span>ఇనుము / Manganese</span>}
                      {language === 'telugu' ? (
                        <p className='range'>
                          5-10 (మిల్లీగ్రాములు/కిలోగ్రాముకు)
                        </p>
                      ) : (
                        <p className='range'>5-10 (mg/kg)</p>
                      )}
                    </li>
                  )}
                  {data?.copper && (
                    <li>
                      Copper<p className='range'>0.2-0.4 (mg/kg)</p>
                    </li>
                  )}
                  {data?.boron && (
                    <li
                      className='fs-6 fw-bold'
                      style={{
                        paddingTop: '10px',
                        height: '80px',
                      }}
                    >
                      {language === 'english' && <span>Boron</span>}
                      {language === 'hindi' && <span>बोरान</span>}
                      {language === 'telugu' && <span>బోరాన్ / Boron</span>}

                      {language === 'telugu' ? (
                        <p className='range'>
                          0.1-0.5 (మిల్లీగ్రాములు/కిలోగ్రాముకు)
                        </p>
                      ) : (
                        <p className='range'>5-0.1-0.5(mg/kg)</p>
                      )}
                    </li>
                  )}
                </ul>
              </td>
              <td className='col_2'>
                <ul className='chart--horizon'>
                  {GenerateBar('Zinc', data?.zinc, [0, 0.5, 1, 5])}
                  {GenerateBar('Iron', data?.iron, [0, 5, 10, 50])}
                  {GenerateBar('Manganese', data?.manganese, [0, 5, 10, 50])}
                  {GenerateBar('Copper', data?.copper, [0, 0.2, 0.4, 10])}
                  {GenerateBar('Boron', data?.boron, [0, 0.1, 0.5, 5])}
                </ul>
              </td>
            </tr>

            {data?.microbial_count && (
              <tr>
                <td>
                  <div className='label'>Microbial </div>
                </td>
              </tr>
            )}

            {data?.microbial_count && (
              <tr>
                <td className='col_1'>
                  <ul className='graph_lables'>
                    <li>
                      Microbial Count<p className='range'>200-800 (µg C/g)</p>
                    </li>
                  </ul>
                </td>
                <td className='col_2'>
                  <ul className='chart--horizon'>
                    {GenerateBar(
                      'Microbial Count',
                      data?.microbial_count,
                      [0, 200, 800, 1800]
                    )}
                  </ul>
                </td>
              </tr>
            )}

            {/* Indicators */}
            <tr>
              <td className='col_1'></td>
              <td className='col_2 '>
                <div className='chart--horizon'>
                  <div
                    style={{
                      position: 'relative',
                      bottom: '-3.75%',
                      display: 'flex',
                      width: '100%',
                      height: '40px',
                      backgroundColor: 'white',
                      marginBottom: '0',
                      justifyContent: 'space-between',
                      marginRight: '0px',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: '#dc3912',
                        padding: '10px 15px',
                        width: '100%',
                        color: 'white',
                      }}
                    >
                      {language === 'english' && <span>Low </span>}
                      {language === 'hindi' && <span>कम </span>}
                      {language === 'telugu' && <span>తక్కువ </span>}
                    </div>
                    <div
                      style={{
                        backgroundColor: '#109618',
                        padding: '10px 15px',
                        width: '100%',
                        color: 'white',
                      }}
                    >
                      {language === 'english' && <span>Sufficient </span>}
                      {language === 'hindi' && <span>पर्याप्त </span>}
                      {language === 'telugu' && <span>తగినంత </span>}
                    </div>
                    <div
                      style={{
                        backgroundColor: '#ff9900',
                        padding: '10px 15px',
                        width: '100%',
                        color: 'white',
                      }}
                    >
                      {language === 'english' && <span>High </span>}
                      {language === 'hindi' && <span>अधिक </span>}
                      {language === 'telugu' && <span>అధిక </span>}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default BarGraph;
