import React from "react";
import Table from 'react-bootstrap/Table';

function interpretation(value, suitable_value)
{
    var value_f = parseFloat(value);
    var suitable_value_f = parseFloat(suitable_value);
    var suitable_value_f_20 = suitable_value_f + (0.20 * suitable_value_f);
    if(value_f<=suitable_value_f)
        return <p className="text-success m-0">Suitable</p>;
    else if (value_f<=suitable_value_f_20)
        return <p className="text-warning m-0">Slighty High</p>;
    else if (value_f>suitable_value_f_20)
        return <p className="text-danger m-0">High</p>
    else
        return null;

}

function WaterTableData(props)
{
    const data = props.data;

    if(data?.water_report==="Yes")
    {
        return(

            <div className="p-4 pb-2">
            <Table striped responsive hover className="water_table align-middle text-center table-borderless" style={{minHeight:'500px'}}>
            <thead>
                <tr>
                    <th colSpan="5" className="text-center" style={{color:'#113e21'}}><h4>Water Analysis</h4></th>
                </tr>   
                <tr>
                    <th>Parameters</th>
                    <th>Unit</th>
                    <th>Result</th>
                    <th>Interpretation</th>
                    <th>Acceptable Value</th>
                </tr>
                </thead> 
                <tbody>
                {data?.w_nitrogen &&
                <tr>
                    <td>Nitrogen</td>
                    <td>ppm</td>
                    <td>{data?.w_nitrogen}</td>
                    <td>{interpretation(data.w_nitrogen,30)}</td>
                    <td> &#60; 30</td>
                </tr>}

                {data?.w_phosphorus &&
                <tr>
                    <td>Phosphorus</td>
                    <td>mg/l</td>
                    <td>{data?.w_phosphorus}</td>
                    <td>{interpretation(data.w_phosphorus,2)}</td>
                    <td>&#60; 2</td>
                </tr>}

                {data?.w_potassium &&
                <tr>
                    <td>Potassium</td>
                    <td>ppm</td>
                    <td>{data?.w_potassium}</td>
                    <td>{interpretation(data.w_potassium,2)}</td>
                    <td>&#60; 2</td>
                </tr>}

                {data?.w_ec &&
                <tr>
                    <td>EC</td>
                    <td>ds/m</td>
                    <td>{data?.w_ec}</td>
                    <td>{interpretation(data.w_ec,3)}</td>
                    <td> &#60; 3</td>
                </tr>}

                {data?.w_tds &&
                <tr>
                    <td>TDS</td>
                    <td>ppm</td>
                    <td>{data?.w_tds}</td>
                    <td>{interpretation(data.w_tds,2000)}</td>
                    <td> &#60; 2000</td>
                </tr>}

                {data?.w_calcium &&
                <tr>
                    <td>Calcium</td>
                    <td>me/l</td>
                    <td>{data?.w_calcium}</td>
                    <td>{interpretation(data.w_calcium,20)}</td>
                    <td> &#60; 20</td>
                </tr>}

                {data?.w_magnesium &&
                <tr>
                    <td>Magnesium</td>
                    <td>me/l</td>
                    <td>{data?.w_magnesium}</td>
                    <td>{interpretation(data.w_magnesium,5)}</td>
                    <td>&#60; 5</td>
                </tr>}

                {data?.w_sodium &&
                <tr>
                    <td>Sodium</td>
                    <td>me/l</td>
                    <td>{data?.w_sodium}</td>
                    <td>{interpretation(data.w_sodium,40)}</td>
                    <td>&#60; 40</td>
                </tr>}

                {data?.w_sulphate &&
                <tr>
                    <td>Sulphate</td>
                    <td>me/l</td>
                    <td>{data?.w_sulphate}</td>
                    <td>{interpretation(data.w_sulphate,20)}</td>
                    <td> &#60; 20</td>
                </tr>}

                {data?.w_carbonate &&
                <tr>
                    <td>Carbonate</td>
                    <td>me/l</td>
                    <td>{data?.w_carbonate}</td>
                    <td>{interpretation(data.w_carbonate,0.1)}</td>
                    <td> &#60; 0.1</td>
                </tr>}

                {data?.w_bicarbonate &&
                <tr>
                    <td>Bicarbonate</td>
                    <td>me/l</td>
                    <td>{data?.w_bicarbonate}</td>
                    <td>{interpretation(data.w_bicarbonate,10)}</td>
                    <td> &#60; 10</td>
                </tr>}

                {data?.w_chloride &&
                <tr>
                    <td>Chloride</td>
                    <td>me/l</td>
                    <td>{data?.w_chloride}</td>
                    <td>{interpretation(data.w_chloride,30)}</td>
                    <td>&#60; 30</td>
                </tr>}

                {data?.w_iron &&
                <tr>
                    <td>Iron</td>
                    <td>ppm</td>
                    <td>{data?.w_iron}</td>
                    <td>{interpretation(data.w_iron,1.5)}</td>
                    <td> &#60; 1.50</td>
                </tr>}

                {data?.w_zinc &&
                <tr>
                    <td>Zinc</td>
                    <td>ppm</td>
                    <td>{data?.w_zinc}</td>
                    <td>{interpretation(data.w_zinc,5)}</td>
                    <td> &#60; 5</td>
                </tr>}

                {data?.w_ph &&
                <tr>
                    <td>pH</td>
                    <td></td>
                    <td>{data?.w_ph}</td>
                    <td>{interpretation(data.w_ph,8.4)}</td>
                    <td>6.5-8.4</td>
                </tr>}

                {data?.w_boron &&
                <tr>
                    <td>Boron</td>
                    <td>ppm</td>
                    <td>{data?.w_boron}</td>
                    <td>{interpretation(data.w_boron,3)}</td>
                    <td> &#60; 3</td>
                </tr>}

                {data?.w_SAR &&
                <tr>
                    <td>SAR</td>
                    <td>ppm</td>
                    <td>{data?.w_SAR}</td>
                    <td>{interpretation(data.w_SAR,3)}</td>
                    <td> &#60; 3</td>
                </tr>}
                </tbody>
            </Table>
            </div>
            
        )
    }
}

export default WaterTableData;