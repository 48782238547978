import axios from "axios";
import { API_URL} from "./Globals";
import Cookies from "universal-cookie";
const cookies = new Cookies();

axios.defaults.withCredentials = true;
class Auth { 
  constructor() {
    this.authenticated = false;
    this.userData = null;
  }

  collectUserData() {
    const userDataa = cookies.get("userData");
    this.userData =  userDataa;
    console.log("userData",this.userData,userDataa);
  }

  isAuthenticated() {
    const accessToken = cookies.get("authSession");
    const refreshToken = cookies.get("refreshSession");
    const userDataa = cookies.get("userData");
    this.userData =  userDataa;
    // console.log("IsAuth Invoked");
    // console.log("userData",this.userData);
    // console.log("this.authenticated",this.authenticated);
    if (!accessToken && !refreshToken) {
      return (this.authenticated = false);
    }
    if (accessToken && refreshToken) {
      return (this.authenticated = true);
    }
    if (!accessToken && refreshToken) {
      axios
        .post(API_URL + "refresh", {
          withCredentials: true,
        })
        .then(function (res) {
          // console.log("aa",res.data);
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error.response);
        });
    }
  }

  getUserData() {
    return this.userData;
  }
}

const auth = new Auth();
export default auth;
