import './PrintTemplate.css';
import logo from '../../../assets/Images/logo.png';

function PrintTemplate() {
  return (
    <div>
      {/* Print Head */}
      <div className='report__header printhead_comp'>
        <div className='report__logo'>
          <img
            className='header__logo'
            alt='logo'
            src={logo}
            style={{ marginRight: '10px' }}
          />
          <div className='header__name label'>Soil Doctor</div>
        </div>

        <div className='header__heading label'>Soil Analysis Report</div>
      </div>
      {/* Print Footer */}
      <div className='report__footer printfoot_comp'>
        <div className='report__footer--grid d-flex justify-content-around'>
          <div>
            <span className='soil__text'>
              Contact Us:
              <br />
            </span>
            +91 8150085009<br></br>
          </div>
          <div>
            <span className='soil__text'>
              Email Us:
              <br />
            </span>
            hello@soildoctor.in
          </div>
        </div>
        <div className='p-2'>
          <p className='copyright_text text-center mb-0'>
            {' '}
            COPYRIGHT ® 2023 Ekosight Technologies Private Limited ALL RIGHTS
            RESERVED.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrintTemplate;
