import React, { useState, useEffect } from "react";
import axios from 'axios';

import { API_URL } from '../../../Globals';
import { schema } from './TestSampleSchema';
import './auth.css'

import "bootstrap/dist/css/bootstrap.css";
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import { Formik, Field, FieldArray, ErrorMessage } from 'formik';
import { HiChevronRight } from "react-icons/hi2";

import { Alert } from 'react-bootstrap';
import { IoWarningOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../Common/Wrapper';



function generateClassName(errors, touched, index, fieldName) {
  const hasError = errors?.samples?.[index]?.[fieldName] && touched?.samples?.[index]?.[fieldName];
  const isSuccess = touched?.samples?.[index]?.[fieldName];
  return `input-button form-control my-md-2 my-1 ${hasError ? 'border-2 border-danger' : isSuccess ? 'border-2 border-success' : ''}`;
}


function SampleTest(props) {
  const [samples, setSamples] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate()


  const handleSubmit = (values) => {
    axios
      .post(API_URL + "user/report", {
        data: values,
        withCredentials: true
      })
      .then(function (res) {
        window.location.href = '/';

      })
      .catch((error) => {
        if (error.response && error.response.status === 403)
          alert("Token Expired Re-login");
        else
          alert("Internal Server Error")
        window.location.href = '/';
      });
  };


  useEffect(() => {
    setSamples(props.value.samples);
  }, [props]);


  return (
    <Wrapper>
      <div className='py-2'>
        <Container >

          <h2 className='fw-bolder'>
            <Alert key='warning' variant='warning' className='mt-2 rounded-4'>
              <IoWarningOutline />{' '}Make sure to enter the correct sample ID in the device!
            </Alert>
          </h2>

          <Row xs={1} sm={1} md={2} lg={3} className='mt-5 d-flex justify-content-center'>
            {samples.length > 0 &&
              samples.map(sample => <Col>
                <Card style={{ minWidth: '100%', maxWidth: '400px' }}>
                  <Card.Body className='justify-center'>
                    <Card.Title className='fw-bolder fs-2'>{sample.sample_id}</Card.Title>
                    <Card.Text>
                      <div className='d-flex flex-column'>
                        <span>Booking ID: {sample.sample_id}</span>
                        <span>Farm Name: {sample.farm_name}</span>
                        <span>Farm Size: {sample.farm_size} {"  "} {sample.farm_size_unit}</span>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>)
            }
          </Row>

          <div className='d-flex justify-content-between align-items-center my-4' style={{ width: '100%' }}>
            <Button variant="dark" onClick={() => { window.location.reload() }}>Back</Button>
            <Alert key="success" variant="success" className='p-0'>
              <span className='mx-4'>After submitting data from device go back to previous reports page</span>
            </Alert>
            <Button variant="dark" onClick={() => { navigate('/reports') }}>Previous Report</Button>
          </div>
        </Container>
      </div>
    </Wrapper>
  )
};

export default SampleTest;