import * as Yup from "yup";


export const schema = Yup.object().shape({
    samples: Yup.array().of(
        Yup.object().shape({
            booking_id:         Yup.string().trim().required("Booking Id is required").min(8, "Too short"),
            sample_id:          Yup.string().trim().required("Sample Id is required").min(8, "Too short"),
            device_id:          Yup.string().trim().required("Device Id is required").min(8, "Too short"),
            collection_date:    Yup.date().required("Date is required").max(new Date(), "Colection Date cannot be in the future"),

            ph:                 Yup.number().required("Required").min(0, "pH should be within 0-14").max(14, "pH should be within 0-14").typeError('A number is required'),
            ec:                 Yup.number().required("Required").min(0, "EC should be within 0-5").max(5, "EC should be within 0-5").typeError('A number is required'),
            oc:                 Yup.number().required("Required").min(0, "OC should be within 0-3").max(3, "OC should be within 0-3").typeError('A number is required'),

            nitrate:         Yup.number().required("Required").min(0, "Nitrate should be within 0-500").max(500, "Nitrate should be within 0-500").typeError('A number is required'),
            ammonia:         Yup.number().required("Required").min(0, "Ammonia should be within 0-500").max(500, "Ammonia should be within 0-500").typeError('A number is required'),
            // nitrogen:           Yup.number().required("Required").min(0, "Nitrogen should be within 0-1000").max(1000, "Nitrogen should be within 0-1000"),
            phosphorus:         Yup.number().required("Required").min(0, "Phosphorus should be within 0-500").max(500, "Phosphorus should be within 0-500").typeError('A number is required'),
            potassium:          Yup.number().required("Required").min(0, "Potassium should be within 0-1000").max(1000, "Potassium should be within 0-1000").typeError('A number is required'),

            //  sulphur:           Yup.number().min(0,"Sulphur should be within 0-100").max(100,"Sulphur should be within 0-100"),
            //  magnesium:         Yup.number().min(0,"Magnesium should be within 0-1000").max(1000,"Boron should be within 0-1000"),
            //  calcium:           Yup.number().min(0,"Calcium should be within 0-4000").max(4000,"Calcium should be within 0-4000"),

            //  zinc:              Yup.number().min(0,"Zinc should be within 0-5").max(5,"Zinc should be within 0-5"),
            //  iron:              Yup.number().min(0,"Iron should be within 0-50").max(50,"Iron should be within 0-50"),
            //  manganese:         Yup.number().min(0,"Manganese should be within 0-50").max(50,"Manganese should be within 0-50"),
            //  copper:            Yup.number().min(0,"Copper should be within 0-10").max(10,"Copper should be within 0-10"),
            //  boron:             Yup.number().min(0,"Boron should be within 0-5").max(5,"Boron should be within 0-5"),

            //  aluminum:          Yup.number().min(0,"Aluminum cannot be negative"),
            //  chlorine:          Yup.number().min(0,"Chlorine cannot be negative"),
            //  molybdenum:        Yup.number().min(0,"Molybdenum cannot be negative"),
            //  nickle:            Yup.number().min(0,"Nickle cannot be negative"),

            //  microbial_count:    Yup.number().min(0,"Microbial Count should be within 0-1800").max(1800,"Microbial Count should be within 0-1800"),
            //  microbial_remarks:  Yup.string().trim().min(2, 'Too Short').max(10000,'Too Long'),

            //  water_report:      Yup.string().required("Please choose one"),

            //waterform
            //  w_collection_date:     Yup.date().when("water_report",{
            //                                         is: "Yes",
            //                                         then: Yup.date().required("Date is required")}),
            //  w_sample_code:         Yup.string().when("water_report",{
            //                                         is: "Yes",
            //                                         then: Yup.string().required("Sample Code is required")}),
            //  w_sample_type:         Yup.string().when("water_report",{
            //                                         is: "Yes",
            //                                         then: Yup.string().required("Sample Type is required")}),
            //  w_nitrogen:           Yup.number().when("water_report",{
            //                                         is: "Yes",
            //                                         then: Yup.number().required("Nitrogen is required").min(0,"Nitrogen cannot be negative")}),
            //  w_phosphorus:         Yup.number().when("water_report",{
            //                                         is: "Yes",
            //                                         then: Yup.number().required("Phosphorus is required").min(0,"Phosphorus cannot be negative")}),
            //  w_potassium:          Yup.number().when("water_report",{
            //                                         is: "Yes",
            //                                         then: Yup.number().required("Potassium is required").min(0,"Potassium cannot be negative")})          
        })
    ),
});

