import React, { useState, useEffect } from 'react';
import { API_URL } from '../../../Globals';
import axios from 'axios';

import "./auth.css";
import "bootstrap/dist/css/bootstrap.css";
import { Alert } from 'react-bootstrap';
import { IoWarningOutline } from 'react-icons/io5';
import Wrapper from '../../Common/Wrapper';

function SelectSample(props) {
  axios.defaults.withCredentials = true;
  const [samples, setSamples] = useState([]);
  const [selectedSamples, setSelectedSamples] = useState([]);

  useEffect(() => {
    axios
      .get(API_URL + "user/bookings", {
        withCredentials: true,
      })
      .then(function (res) {
        setSamples(res.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403)
          alert("Token Expired Re-login");
        else
          alert("Internal Server Error")
        window.location.href = '/';
      });
  }, []);


  const handleCheckboxChange = (sampleId) => {
    let isSelected = selectedSamples.includes(sampleId);
    if (isSelected) {
      setSelectedSamples(selectedSamples.filter((item) => item !== sampleId))
    }
    else if (selectedSamples.length < 3) {
      setSelectedSamples([...selectedSamples, sampleId])
    }
  }

  var getBookings = () => {
    var rows = samples;
    return rows.map((record) => (
      <tr key={record.booking_id + "_" + record.sample_id}>
        <td data-label="BOOKING ID">{record.booking_id}</td>
        <td data-label="SAMPLE ID">{record.sample_id.toUpperCase()}</td>
        <td data-label="SAMPLE NO">{record.sample_no}/{record.samples_count}</td>
        <td data-label="FARM NAME">{record.farm_name.toUpperCase()}</td>
        <td data-label="FARM SIZE">{record.farm_size.toUpperCase()}&nbsp;{record.farm_size_unit.toUpperCase()}</td><td data-label="DATE"> {new Date(Date.parse(record.created_at)).toDateString()} </td>
        <td data-label="ACTIONS">
          <input
            type="checkbox"
            onChange={() => handleCheckboxChange(record.sample_id)}
            checked={selectedSamples.includes(record.sample_id)}
          />
        </td>
      </tr>
    ));
  };

  const sampleTestReadings = {
    sample_id: '',
    booking_id: '',
    device_id: "EKO1332JJ23",
    collection_date: new Date().toISOString().split("T")[0],
    ph: '',
    ec: '',
    oc: '',
    // nitrogen: '',
    nitrate: '',
    ammonia: '',
    phosphorus: '',
    potassium: '',
    remarks: ''
  }

  const getDataForSelectedIds = () => {

    const selectedSamplesData = samples.filter((item) => selectedSamples.includes(item.sample_id));
    var updatedSamples = [];

    for (let i = 0; i < selectedSamplesData.length; i++) {
      var sample = sampleTestReadings;
      sample.sample_id = selectedSamplesData[i].sample_id;
      sample.booking_id = selectedSamplesData[i].booking_id;
      updatedSamples.push(sample);
    }

    props.value.sampleParameters = updatedSamples;
    props.value.samples = selectedSamplesData;
    props.setValues(props.value);
    props.nextStep();

  };


  return (
    <Wrapper>
      <div className='py-md-3'>
        <h3 className='fw-bolder'>
        </h3>
        <h3 className='fw-bolder'> Select Samples to Proceed</h3>
        <Alert key='warning' variant='warning' className='mt-2 rounded-4'>
          <IoWarningOutline />{' '} You can select upto 3 samples!
        </Alert>
        <table className={`table table-striped text-center table-borderless bg-white rounded-4 ${samples.length > 1 ? 'align-middle' : ''}`}>
          <thead className="table-dark">
            <tr>
              <th>BOOKING ID</th>
              <th>SAMPLE ID</th>
              <th>SAMPLE NO</th>
              <th>FARM NAME</th>
              <th>FARM SIZE</th>
              <th>DATE</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {samples.length > 0 ? (getBookings()) :
              (<tr><td colSpan="7">No Entries Found.. </td></tr>)}
          </tbody>
        </table>

        <div className="d-flex flex-md-row justify-content-between">
          <button type="button" className="btn btn-dark px-4" onClick={() => { window.location.href = "/" }}>Back</button>
          <button type="button" className="btn btn-dark px-3" onClick={() => getDataForSelectedIds()} disabled={selectedSamples <= 0}>Continue</button>
        </div>
      </div>
    </Wrapper>
  );
}

export default SelectSample