import React, { useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';

function PaginationComponent({ currentPage, totalPages, onPageChange, onItemsPerPageChange }) {
  const pageNumbers = [];

  for (let number = 1; number <= totalPages; number++) {
    pageNumbers.push(number);
  }

  const itemsPerPageOptions = [5, 10, 15];
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    onItemsPerPageChange(newItemsPerPage);
  };

  return (
    <div className="d-flex justify-content-between mt-4 p-2">
      <div>        
        <Pagination>
          <Pagination.Prev
            disabled={currentPage === 1}
            onClick={() => onPageChange(currentPage - 1)}
          />
          {pageNumbers.map((number) => (
            <Pagination.Item
              key={number}
              active={number === currentPage}
              onClick={() => onPageChange(number)}
            >
              {number}
            </Pagination.Item>
          ))}
          <Pagination.Next
            disabled={currentPage === totalPages}
            onClick={() => onPageChange(currentPage + 1)}
          />
        </Pagination>
      </div>
      <div>
        <Form.Control
          as="select"
          className="form-select"
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
        >
          {itemsPerPageOptions.map((option) => (
            <option key={option} value={option}>
              {option} rows
            </option>
          ))}
        </Form.Control>
      </div>
    </div>
  );
}

export default PaginationComponent;
