import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Offcanvas,
} from 'react-bootstrap';

import './Layout.css';
import { API_URL } from '../../../Globals';
import SoilDoctorLogo from '../../../assets/Images/SoilDoctorLogo.png';
import { BiUserCircle, BiBell, BiLogOut, BiHome } from 'react-icons/bi';
import { LiaUserEditSolid } from 'react-icons/lia';

function Header() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [notificationWindow, setNotificationWindow] = useState(false);

  useEffect(() => {
    axios
      .get(API_URL + 'user/info', {
        withCredentials: true,
      })
      .then(function (res) {
        setUserInfo(res.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403)
          alert('Token Expired Re-login');
        else alert('Internal Server Error');
        window.location.href = '/';
      });
  }, []);

  const logout = () => {
    axios
      .get(API_URL + 'logout')
      .then((res) => {
        localStorage.clear();
        sessionStorage.clear();
        // window.location.reload();
        window.location.href = '/';
      })
      .catch((err) => {
        console.log('err', err);
        window.location.reload();
      });
  };

  return (
    <>
      <Navbar bg='light' data-bs-theme='light'>
        <Container fluid className='px-md-4 px-3'>
          <Navbar.Brand as={Link} to='/'>
            <img
              className='navbar-brand'
              alt='logo'
              src={SoilDoctorLogo}
              style={{ width: '220px', maxWidth: '40vw' }}
            />
          </Navbar.Brand>

          <Nav className='justify-content-end'>
            {/* Notification */}
            <Nav.Link
              variant='dark'
              onClick={() => {
                setNotificationWindow(true);
              }}
            >
              <BiBell size={'28px'} className='text-black ' />
              <span className='position-relative top-0 start-0 translate-middle badge rounded-pill bg-danger'>
                1
              </span>
            </Nav.Link>

            {/* User */}
            <NavDropdown
              title={<BiUserCircle size={'30px'} className='text-black' />}
              id='collasible-nav-dropdown'
              menuVariant='light'
              align='end'
            >
              <NavDropdown.Item className='text-black'>
                {userInfo?.name} <br /> +91&nbsp;{userInfo?.mobile_no}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to='/'
                className='d-flex align-items-center gap-2'
              >
                <BiHome /> Home
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to='/profile'
                className='d-flex align-items-center gap-2'
              >
                <LiaUserEditSolid /> Profile
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                className='text-danger d-flex align-items-center gap-2'
                onClick={logout}
              >
                <BiLogOut transform='rotate(180)' /> Logout
              </NavDropdown.Item>
            </NavDropdown>

            {/* Notification Sidebar */}
            <Offcanvas
              className='bg-light'
              show={notificationWindow}
              onHide={() => {
                setNotificationWindow(false);
              }}
              backdrop='static'
              placement='end'
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  <h2 className='fw-bolder'>Notifications</h2>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className='notification-item'>
                  <div className='icon'>
                    <BiUserCircle />
                  </div>
                  <div className='content'>
                    <div className='text'>Report Created! Check it here</div>
                    <div className='time'>2 hours ago</div>
                  </div>
                </div>
                <div className='notification-item alternate'>
                  <div className='icon'>
                    <BiUserCircle />
                  </div>
                  <div className='content'>
                    <div className='text'>Sample Testing in Progress</div>
                    <div className='time'>3 hours ago</div>
                  </div>
                </div>
                <div className='notification-item'>
                  <div className='icon'>
                    <BiUserCircle />
                  </div>
                  <div className='content'>
                    <div className='text'>Verify your Email</div>
                    <div className='time'>5 hours ago</div>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
