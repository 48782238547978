import React, { useState, useEffect } from "react";
import axios from 'axios';

import { API_URL } from '../../../Globals';
import UpdateProfile from "./UpdateProfile";

import { Row, Col, Card } from "react-bootstrap";
import { FaPencilAlt, FaTimes, FaPhoneAlt, FaEnvelope, FaLandmark, FaMapMarkerAlt } from 'react-icons/fa';
import Wrapper from '../../Common/Wrapper';


function UserProfile() {
  const [userInfo, setUserInfo] = useState({})
  const [editInfo, setEditInfo] = useState(false)

  useEffect(() => {
    axios
      .get(API_URL + "user/details", {
        withCredentials: true,
      })
      .then(function (res) {
        setUserInfo(res.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403)
          alert("Token Expired Re-login");
        else
          alert("Internal Server Error")
        window.location.href = '/';
      });
  }, []);

  return (
    <Wrapper>
      <div className="profile-page">
        <Row>
          <Col md={6}>
            <Card className="p-0 rounded-4">
              <Card.Body className="p-0">
                {/* <div className="row align-items-center p-5">
                <div className="col-auto">
                  <div
                    className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center fw-bolder"
                    style={{
                      width: '140px',
                      height: '140px',
                      fontSize: '48px',
                    }}>
                    {(userInfo.first_name ? userInfo.first_name[0] : '') + (userInfo.last_name ? userInfo.last_name[0] : '')}
                  </div>
                </div>
                <div className="col col-xs-12">
                  <h1 className="fw-bold">{userInfo.first_name} {userInfo.last_name}</h1>
                  <p className="text-muted">Joined on {new Date(userInfo.created_at).toLocaleString('en-US', { month: 'long', year: 'numeric' })}</p>
                </div>
              </div> */}

                <div className="row align-items-center p-md-5 p-4">
                  <div className="col-md-auto text-center mb-3 mb-md-0">
                    <div
                      className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center fw-bolder mx-auto"
                      style={{
                        width: '140px',
                        height: '140px',
                        fontSize: '56px',
                      }}
                    >
                      {(userInfo.first_name ? userInfo.first_name[0] : '') + (userInfo.last_name ? userInfo.last_name[0] : '')}
                    </div>
                  </div>
                  <div className="col-md col-xs-12 text-center">
                    <h1 className="fw-bold text-md-start">{userInfo.first_name} {userInfo.last_name}</h1>
                    <p className="text-muted text-md-start m-0">Joined on {new Date(userInfo.created_at).toLocaleString('en-US', { month: 'long', year: 'numeric' })}</p>
                  </div>
                </div>


                <div className="bg-light rounded-bottom-4 p-md-5 p-4">
                  <div className="position-relative">

                    <div className="position-absolute top-0 end-0 p-0" style={{ marginTop: `${editInfo ? '-10px' : '-32px'}`, marginRight: "-10px" }}>
                      {!editInfo && <FaPencilAlt size={18} onClick={() => setEditInfo(true)} />}
                      {editInfo && <FaTimes size={28} onClick={() => setEditInfo(false)} />}
                    </div>

                    {!editInfo && <div className="user-info fs-5">
                      <Row className="my-4">
                        <Col className="col-auto"><FaPhoneAlt /></Col>
                        <Col style={{ wordBreak: 'break-all' }}>+91 {userInfo.mobile_no}</Col>
                      </Row>
                      <Row className="my-4">
                        <Col className="col-auto"><FaEnvelope /></Col>
                        <Col style={{ wordBreak: 'break-all' }}>{userInfo.email_id != "" ? userInfo.email_id : "NA"}</Col>
                      </Row>
                      <Row className="my-4">
                        <Col className="col-auto"><FaLandmark /></Col>
                        <Col style={{ wordBreak: 'break-all' }}>{userInfo.address}</Col>
                      </Row>
                      <Row className="my-4">
                        <Col className="col-auto"><FaMapMarkerAlt /></Col>
                        <Col style={{ wordBreak: 'break-all' }}>{userInfo.city}, {userInfo.state} - {userInfo.pincode}</Col>
                      </Row>
                    </div>}

                    {editInfo && <div className="user-info-edit">
                      <UpdateProfile userData={userInfo} />
                    </div>}

                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6}>

            <Card className="rounded-4">
              <Card.Body className="">
                <h3 className="fw-bold text-center pt-0"> Information </h3>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Wrapper>
  )

}

export default UserProfile;