import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../Globals';

import axios from 'axios';

import Auth from '../../../Auth';
import './auth.css';
import 'bootstrap/dist/css/bootstrap.css';
import image from '../../../assets/Images/LoginImage.png';
import SoilDoctorLogo from '../../../assets/Images/SoilDoctorLogo.png';
import { Spinner } from 'react-bootstrap';

function Login(props) {
  // useEffect(() => {
  //     const queryParameters = new URLSearchParams(window.location.search);
  //     const key = queryParameters.get("data");
  //     if (key != null) {
  //         axios
  //             .post(API_URL + "partnerLogin", {
  //                 "key": key
  //             })
  //             .then(function (res) {
  //                 console.log("res", res);
  //                 if (res.status === 200 && res.data.verified) {
  //                     localStorage.clear();
  //                     sessionStorage.setItem('info', JSON.stringify(res.data.info));
  //                     window.location = '/'
  //                 }
  //                 else {
  //                     localStorage.clear();
  //                     window.location = '/'
  //                 }
  //             })
  //             .catch((error) => {
  //                 if (error.response.status === 401 || error.response.status === 500) {
  //                     window.location = '/'
  //                     alert(error.response.data.msg)
  //                 }
  //                 console.log(error);
  //             });
  //     }
  // }, []);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(120); // 120 seconds
  const [message, setMessage] = useState({
    error: '',
    success: '',
  });
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');

  useEffect(() => {
    let interval;

    if (isResendDisabled && resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsResendDisabled(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isResendDisabled, resendTimer]);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);

    if (value.length === 10) {
      setIsPhoneNumberValid(true);
    } else {
      setIsPhoneNumberValid(false);
    }
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;
    setOtp(value);

    if (value.length === 6) {
      setIsOtpValid(true);
    } else {
      setIsOtpValid(false);
    }
  };

  const handleResendOtp = (e) => {
    if (resendTimer <= 0) {
      setIsResendDisabled(true);
      setResendTimer(120);
      handleSendOtp(e);
    }
  };

  const handleSendOtp = (e) => {
    var mobno = phoneNumber * 1;
    setMessage({ error: '' });
    setMessage({ success: '' });
    e.preventDefault();

    if (!isPhoneNumberValid) {
      setMessage({ error: 'Please enter the 10 digit Indian mobile No.' });
    } else if (isPhoneNumberValid) {
      const regex = /^[6-9][0-9]{9}$/i;
      if (!regex.test(mobno))
        setMessage({
          error: 'Please enter a valid 10 digit Indian mobile No.',
        });
      else {
        setLoading(true);
        axios
          .post(
            API_URL + 'sendOTP',
            { phone: phoneNumber, otp },
            {
              headers: {
                Authorization: btoa(`${phoneNumber}`),
              },
            }
          )
          .then(function (res) {
            console.log('RESPONSE: ', res.data.description);
            setDescription(res.data.description);
            setIsOtpSent(true);
            setIsPhoneNumberValid(true);
            setIsResendDisabled(true);
            setResendTimer(120);
            setLoading(false);
            setMessage({ success: res.data.msg });
          })
          .catch(function (error) {
            setMessage({ error: 'Internal Server Error!' });
            setLoading(false);
          });
      }
    }
  };

  const confirmOtp = (e) => {
    setMessage({ error: '' });
    setMessage({ success: '' });
    e.preventDefault();

    if (!isOtpValid) {
      setMessage({ error: 'Please enter the 6 digit OTP.' });
    } else {
      setLoading(true);
      axios
        .post(
          API_URL + 'verifyOTP',
          { description, otp },
          {
            headers: {
              Authorization: btoa(`${phoneNumber}:${otp}`),
            },
          }
        )
        .then(function (res) {
          setMessage({ success: 'OTP Verified!' });
          localStorage.clear();
          if (res.data.isNewUser === true) {
            props.value.phone = phoneNumber;
            props.setValues(props.value);
            props.nextStep();
          } else {
            Auth.collectUserData();
            window.location.reload();
          }
          setLoading(false);
        })
        .catch(function (error) {
          if (error.response) {
            if (
              error.response.status === 504 ||
              error.response.status === 498
            ) {
              setMessage({ ...error, error: error.response.data.msg });
              setTimeout(function () {
                window.location.href = '/';
              }, 1000);
            } else setMessage({ ...error, error: error.response.data.msg });
          } else setMessage({ ...error, error: 'Internal Server Error!' });
          setLoading(false);
        });
    }
  };

  const Reset = () => {
    setMessage({ error: '' });
    setMessage({ success: '' });
    setPhoneNumber('');
    setOtp('');
    setIsOtpSent(false);
    setIsPhoneNumberValid(false);
    setIsResendDisabled(true);
    setResendTimer(120);
  };

  return (
    <div className='login-container m-0'>
      <div className='login-left'>
        <div className='logo'>
          <img src={SoilDoctorLogo} alt='Logo' width='250px' />
        </div>
        <div className='left-content'>
          <h1 className='fw-bolder pt-5'>Welcome to Soil Doctor! </h1>
          <img src={image} alt='Background' className='responsive-image' />
          <figure className='w-75'>
            <blockquote className='blockquote'>
              <h3>
                To forget how to dig the earth and to tend the soil is to forget
                ourselves.
              </h3>
            </blockquote>
            <figcaption className='blockquote-footer'>
              <cite title='Source Title'>Mohandas K. Gandhi</cite>
            </figcaption>
          </figure>
        </div>
      </div>
      <div className='login-right'>
        <div className='login-form' contentEditable={false}>
          <h1 className='mb-4'>Sign In</h1>
          <form>
            <div className='form-floating mb-3'>
              <input
                required
                value={phoneNumber}
                disabled={isOtpSent}
                placeholder='Mobile Number'
                onChange={handlePhoneNumberChange}
                className='form-control'
              />
              <label htmlFor='first_name'>
                Mobile Number<span className='text-danger'>&nbsp;*</span>
              </label>
            </div>

            {isOtpSent == true ? (
              // If OTP sent
              <>
                <div className='form-floating'>
                  <input
                    required
                    type='number'
                    placeholder='OTP'
                    value={otp}
                    disabled={!isOtpSent}
                    onChange={handleOtpChange}
                    className='form-control mb-3'
                  />
                  <label htmlFor='first_name'>
                    One Time Password
                    <span className='text-danger'>&nbsp;*</span>
                  </label>
                </div>

                {message.error && (
                  <div className='text-danger'>{message.error}</div>
                )}
                {message.success && (
                  <div className='text-success'>{message.success}</div>
                )}

                <button
                  type='submit'
                  onClick={confirmOtp.bind()}
                  className='mt-2 w-100 text-center btn btn-dark'
                  disabled={loading}
                >
                  {loading && (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                  <span> Verify OTP</span>
                </button>

                <div
                  className='my-2'
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <button
                    type='reset'
                    onClick={Reset}
                    className='w-25 text-center btn btn-dark'
                  >
                    Reset
                  </button>
                  &nbsp;
                  <button
                    onClick={handleResendOtp}
                    className='w-75 text-center btn btn-dark'
                    disabled={isResendDisabled}
                  >
                    Resend OTP {isResendDisabled && `in ${resendTimer}s`}
                  </button>
                </div>
              </>
            ) : (
              // If OTP not sent
              <>
                <div className='form-floating'>
                  <input
                    readOnly
                    disabled
                    value=''
                    type='number'
                    placeholder='OTP'
                    className='form-control mb-3'
                  />
                  <label htmlFor='first_name'>One Time Password</label>
                </div>

                {message.error && (
                  <div className='text-danger'>{message.error}</div>
                )}
                {message.success && (
                  <div className='text-success'>{message.success}</div>
                )}
                <button
                  type='submit'
                  onClick={handleSendOtp.bind()}
                  className='w-100 text-center btn btn-dark mt-2'
                  disabled={!isPhoneNumberValid || loading}
                >
                  {loading && (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                  <span> Send OTP</span>
                </button>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
