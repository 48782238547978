import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import { API_URL } from "../../../Globals";
import SoilDoctorLogo from "../../../assets/Images/SoilDoctorLogo.png";

import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import { Formik, Field } from "formik";
import Wrapper from "../../Common/Wrapper";

function FollowUpForm() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [formQuestions, setFormQuestions] = useState([]);
  const [initialValues, setInitialValues] = useState([]);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    navigate("/");
  };

  const goBack = (e) => {
    navigate("/bookings", { state: e });
  };

  const handleSubmit = (values) => {
    console.log(values);
    // var data = {
    //   sample_id: state,
    //   form_answers: JSON.stringify(values.data),
    // };
    // console.log('handleSubmit', data);
    // // handleShow();

    // axios
    //   .post(API_URL + 'user/response', {
    //     data: data,
    //     withCredentials: true,
    //   })
    //   .then(function (res) {
    //     console.log('res', res.data);
    //     handleShow();
    //   })
    //   .catch((error) => {
    //     if (error.response && error.response.status === 403)
    //       alert('Token Expired Re-login');
    //     else alert('Internal Server Error');
    //     window.location.href = '/';
    //   });
  };

  useEffect(() => {
    axios
      .get(API_URL + "user/questions", {
        withCredentials: true,
      })
      .then(function (res) {
        console.log("questions", res.data);
        setFormQuestions(res.data.form_question);
        setInitialValues(res.data.form_question);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403)
          alert("Token Expired Re-login");
        else alert("Internal Server Error");
        window.location.href = "/";
      });
  }, []);

  return (
    <Wrapper>
      <div className="p-2">
        <h2 className="fw-bolder">Follow Up Questions - Sample {state} </h2>
        {/* <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className='py-md-2'>
              <ol>
                {formQuestions?.map((section, sectionIndex) => (
                  <li className='fs-3' key={sectionIndex}>
                    <h3 className='fs-3 pb-0 mt-3'>{section.label}</h3>
                    <ol type='a'>
                      {section.questions.map((question, questionIndex) => (
                        <li className='fs-5 py-md-1' key={questionIndex}>
                          <p className='my-1'>
                            {question.question}
                            {question.is_required ? (
                              <span className='text-danger'>&nbsp;*</span>
                            ) : (
                              ''
                            )}
                          </p>
                          <Field
                            type='text'
                            required={question.is_required}
                            className={`form-control rounded-2`}
                            name={`data.${section.label}.${questionIndex}.answer`}
                          />
                        </li>
                      ))}
                    </ol>
                  </li>
                ))}
              </ol>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '0px',
                  justifyContent: 'center',
                }}
              >
                <button
                  type='button'
                  onClick={() => {
                    goBack();
                  }}
                >
                  Back
                </button>
                &nbsp;
                <button type='submit'>Submit</button>
              </div>
            </form>
          )}
        // </Formik> */}
        <Formik
          initialValues={{
            s1a1: "",
            s1a2: "",
            s2a1: "",
            s2a2: "",
            s2a3: "",
            s2a4: "",
            s3a1: "",
            s3a2: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            const formDataJSON = {
              "What was the yield of your crops?": {
                "How much did you harvest from your previous crop? (Please specify: kg/Quintal/Ton)":
                  values.s1a1,
                "How about the yield from last year's crop?": values.s1a2,
              },
              "Tell us about your farming practices": {
                "Which type of fertilizer do you use?": values.s2a1,
                "Have you used any fertilizers or manures this season?":
                  values.s2a2,
                "How much did you spend on fertilizers or manures?":
                  values.s2a3,
                "Did you add any micronutrients to your soil?": values.s2a3,
              },
              "Let's talk about challenges": {
                "What's the most common disease that affects your crops?":
                  values.s3a1,
                "Do you have any other concern about your yield or soil health?":
                  values.s3a2,
              },
            };
            console.log("formdatajson: ", formDataJSON);
            axios
              .post(API_URL + "user/follow-up-form/5123", {
                user_id: "client user",
                form_data: formDataJSON,
                status: "A",
                withCredentials: true,
              })
              .then(function (res) {
                console.log("res", res.data);
                handleShow();
              })
              .catch((error) => {
                if (error.response && error.response.status === 403)
                  alert("Token Expired Re-login");
                else alert("Internal Server Error");
                window.location.href = "/";
              });
          }}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => (
            <div>
              <form onSubmit={handleSubmit} className="py-md-2">
                <ol>
                  {/* section 1 */}
                  <li className="fs-3">
                    <h3 className="fs-3 pb-0 mt-3">
                      What was the yield of your crops?
                    </h3>
                    <ol type="a">
                      {/* ques 1 of section 1 */}
                      <li className="fs-5 py-md-1">
                        <p className="my-1">
                          How much did you harvest from your previous crop?
                          (Please specify: kg/Quintal/Ton){" "}
                          <span className="text-danger">&nbsp;*</span>
                        </p>
                        <Field
                          type="text"
                          required
                          name="s1a1"
                          value={values.s1a1}
                          onChange={handleChange}
                        />
                      </li>
                      {/* ques 2 of section 1 */}
                      <li className="fs-5 py-md-1">
                        <p className="my-1">
                          How about the yield from last year's crop?
                        </p>
                        <Field
                          type="text"
                          required
                          name="s1a2"
                          value={values.s1a2}
                          onChange={handleChange}
                        />
                      </li>
                    </ol>
                  </li>
                  {/* section 2 */}
                  <li className="fs-3">
                    <h3 className="fs-3 pb-0 mt-3">
                      Tell us about your farming practices:
                    </h3>
                    <ol type="a">
                      {/* ques 1 of section 2 */}
                      <li className="fs-5 py-md-1">
                        <p className="my-1">
                          Which type of fertilizer do you use?
                        </p>
                        <Field
                          type="text"
                          required
                          name="s2a1"
                          value={values.s2a1}
                          onChange={handleChange}
                        />
                      </li>
                      {/* ques 2 of section 2 */}
                      <li className="fs-5 py-md-1">
                        <p className="my-1">
                          Have you used any fertilizers or manures this season?
                          <span className="text-danger">&nbsp;*</span>
                        </p>
                        <Field
                          type="text"
                          required
                          name="s2a2"
                          value={values.s2a2}
                          onChange={handleChange}
                        />
                      </li>
                      {/* ques 3 of section 2 */}
                      <li className="fs-5 py-md-1">
                        <p className="my-1">
                          How much did you spend on fertilizers or manures?
                        </p>
                        <Field
                          type="text"
                          required
                          name="s2a3"
                          value={values.s2a3}
                          onChange={handleChange}
                        />
                      </li>
                      {/* ques 4 of section 2 */}
                      <li className="fs-5 py-md-1">
                        <p className="my-1">
                          Did you add any micronutrients to your soil?
                          <span className="text-danger">&nbsp;*</span>
                        </p>
                        <Field
                          type="text"
                          required
                          name="s2a4"
                          value={values.s2a4}
                          onChange={handleChange}
                        />
                      </li>
                    </ol>
                  </li>
                  {/* section 3 */}
                  <li className="fs-3">
                    <h3 className="fs-3 pb-0 mt-3">
                      Let's talk about challenges:
                    </h3>
                    <ol type="a">
                      {/* ques 1 of section 3 */}
                      <li className="fs-5 py-md-1">
                        <p className="my-1">
                          What's the most common disease that affects your
                          crops?
                        </p>
                        <Field
                          type="text"
                          required
                          name="s3a1"
                          value={values.s3a1}
                          onChange={handleChange}
                        />
                      </li>
                      {/* ques 2 of section 3 */}
                      <li className="fs-5 py-md-1">
                        <p className="my-1">
                          Do you have any other concerns about your yield or
                          soil health?
                          <span className="text-danger">&nbsp;*</span>
                        </p>
                        <Field
                          type="text"
                          required
                          name="s3a2"
                          value={values.s3a2}
                          onChange={handleChange}
                        />
                      </li>
                    </ol>
                  </li>
                </ol>
                <div
                  style={{
                    display: "flex",
                    marginLeft: "0px",
                    justifyContent: "center",
                  }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      goBack();
                    }}
                  >
                    Back
                  </button>
                  &nbsp;
                  <button type="submit" onClick={onsubmit}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </Formik>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <img className="" alt="logo" src={SoilDoctorLogo} width="200px" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Thank you for filling up this form!!<br></br>
            Our representative will contact you within next 24 hours.
            <br></br>
            <br></br>
          </Modal.Body>
          :
          <Modal.Footer>
            <button onClick={handleClose}> Close </button>
          </Modal.Footer>
        </Modal>
      </div>
    </Wrapper>
  );
}

export default FollowUpForm;
