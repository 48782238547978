import React, { useState } from 'react';
import Login from './Login';
import UserDetails from './UserDetails';

const StepForm = ({ toggleForm, onAuthenticated }) => {
	const [ state, setState ] = useState({
		phone: '',
		otp: ''
	});
	
	const { phone, hash, otp } = state;
	var value = { phone, hash, otp };

	const [step, setStep] = useState(1)
	
	const handleChange = (input) => (e) => {
		setState({...state, [input]: e.target.value });
	};

	const nextStep = () => {       
		setStep(prevStep => prevStep + 1)
	};

	const prevStep = () => {        
		setStep(prevStep => prevStep - 1)
	};

	const setValues = (e) => {
		console.log("setValues",e)
		setState(e)
		console.log("setValues",state)

	}

	// hashHandleChange={hashHandleChange} 

	switch (step) {
		case 1:
			return <Login nextStep={nextStep} handleChange={handleChange} value={value} setValues={setValues} toggleForm={toggleForm} />;
        case 2:
			return <UserDetails nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} value={state} onAuthenticated = {onAuthenticated} />;
        default:
            return <Login nextStep={nextStep} handleChange={handleChange} value={value} toggleForm={toggleForm}/>  
            
    }
};
  export default StepForm;