import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Modal from 'react-bootstrap/Modal';
import { Row, Col, Form } from 'react-bootstrap';

import { Formik, Field, FieldArray, ErrorMessage } from 'formik';
import { HiChevronRight } from 'react-icons/hi2';
import Select from 'react-select';

import { API_URL } from '../../../Globals';
import { schema } from './BookingSchema';
import SoilDoctorLogo from '../../../assets/Images/SoilDoctorLogo.png';

// Generates classes for similar input fields
function GenerateClassName(errors, touched, index, fieldName) {
  const hasError =
    errors?.samples?.[index]?.[fieldName] &&
    touched?.samples?.[index]?.[fieldName];
  const isSuccess = touched?.samples?.[index]?.[fieldName];
  return `form-control ${
    hasError
      ? 'border-2 border-danger'
      : isSuccess
      ? 'border-2 border-success'
      : ''
  }`;
}

// Main form
function BookingFormikForm(props) {
  const navigate = useNavigate();

  const [bookingSummary, setBookingSummary] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    navigate('/');
  };

  window.scrollTo(0, 0);

  const sampleDetails = {
    farm_name: '',
    farm_size: '',
    farm_size_unit: 'Acres',
    previous_crop: '',
    next_crop: '',
    sample_type: 'SOIL',
    comments: '',
  };

  const [samples, setSamples] = useState([sampleDetails]);

  const handleSubmit = (values, actions) => {
    const booking = {
      samples_count: values.samples.length,
      samples: values.samples,
    };

    // Makes API call for sample booking
    axios
      .post(API_URL + 'user/booking', {
        data: booking,
        withCredentials: true,
      })
      .then(function (res) {
        const Ids = res.data.samples.map((str) => {
          const parts = str.split(': ');
          return parts[1];
        });
        setBookingSummary(
          values.samples.map((sample, index) => {
            return { ...sample, sample_id: Ids[index] };
          })
        );
        handleShow();
        actions.resetForm();
      })
      .catch((error) => {
        if (error.response && error.response.status === 403)
          alert('Token Expired Re-login');
        else alert('Internal Server Error');
        window.location.href = '/';
      });
  };

  // Generates Fields for booking form
  const GenerateFields = (label, fieldName, size, index, errors, touched) => {
    return (
      <Col md={props.home ? 12 : size}>
        <div className='form-floating'>
          <Field
            type='text'
            name={`samples[${index}].${fieldName}`}
            placeholder={label}
            className={GenerateClassName(errors, touched, index, fieldName)}
          />
          <Form.Label htmlFor={`samples[${index}].${fieldName}`}>
            {label}{' '}
            {label !== 'Comments' && (
              <span className='text-danger'>&nbsp;*</span>
            )}
          </Form.Label>
          <ErrorMessage
            className='form_errors'
            name={`samples[${index}].${fieldName}`}
            component='div'
          />
        </div>
      </Col>
    );
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: 'black',
      '&:after': {
        content: '" *"',
        color: 'red',
      },
    }),
  };

  return (
    <div>
      <Row className={`${props.home ? 'd-block' : ''}`}>
        <Formik
          initialValues={
            sessionStorage.getItem('bookingData')
              ? {
                  samples: [
                    JSON.parse(sessionStorage.getItem('bookingData'))[0],
                    sampleDetails,
                  ],
                }
              : { samples: samples }
          }
          validationSchema={schema}
          onSubmit={(values, actions) => {
            // convert the next crop to usable format
            values.samples.map(
              (sample) =>
                (sample.next_crop = sample.next_crop.split('(')[0].trim())
            );
            handleSubmit(values, actions);
            actions.setSubmitting();
          }}
        >
          {({
            isSubmitting,
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form
              className={`form__details`}
              onSubmit={handleSubmit}
              onChange={handleChange}
            >
              <FieldArray name='samples'>
                {(arrayHelpers) => (
                  <div className='mt-4'>
                    {values.samples.map((sample, index) => (
                      <div key={index} className={`mt-4 pb-3 bg-red`}>
                        <Row
                          xs={1}
                          md={props.home ? 12 : 6}
                          className='mb-3 g-4'
                        >
                          <Col md={props.home ? 12 : 1}>
                            <div
                              className='form-control h-100 d-flex align-items-center justify-content-center border-success text-success fw-bolder'
                              style={{ minWidth: '44px' }}
                            >
                              S{index + 1}
                            </div>
                          </Col>

                          {GenerateFields(
                            'Farm Name',
                            'farm_name',
                            5,
                            index,
                            errors,
                            touched
                          )}

                          {/* farm size & farm_size_unit */}
                          <Col md={props.home ? 12 : 6}>
                            <div className='input-group'>
                              <div className='form-floating'>
                                <Field
                                  type='number'
                                  step='0.1'
                                  name={`samples[${index}].farm_size`}
                                  placeholder="Farm's Size"
                                  className={GenerateClassName(
                                    errors,
                                    touched,
                                    index,
                                    'farm_size'
                                  )}
                                  id='farm-size'
                                />
                                <Form.Label
                                  htmlFor={`samples[${index}].farm_size`}
                                >
                                  Farm's Size
                                  <span className='text-danger'>&nbsp;*</span>
                                </Form.Label>
                              </div>
                              <Field
                                as='select'
                                name={`samples[${index}].farm_size_unit`}
                                className={`input-group-text w-50 text-black ${
                                  errors.samples?.[index]?.farm_size_unit &&
                                  touched.samples?.[index]?.farm_size_unit
                                    ? 'border-danger bg-danger'
                                    : touched.samples?.[index]?.farm_size_unit
                                    ? 'border-success bg-success'
                                    : ''
                                }`}
                              >
                                {[
                                  'Acres',
                                  'Hectare',
                                  'Bigha',
                                  'Gaj',
                                  'Kanal',
                                  'Biswa',
                                  'Dismil',
                                  'Killa',
                                  'Marla',
                                  'Nali',
                                  'Guntha',
                                  'Square Karam',
                                  'Ankanam',
                                  'Gajam',
                                  'Chatak',
                                  'Dhur',
                                  'Perch',
                                  'Lessa',
                                  'Murabba',
                                  'Biswa Kacha',
                                  'Pura',
                                ].map((unit) => (
                                  <option key={unit} value={unit}>
                                    {unit}
                                  </option>
                                ))}
                              </Field>
                            </div>
                            <ErrorMessage
                              className='form_errors'
                              name={`samples[${index}].farm_size_unit`}
                              component='div'
                            />
                          </Col>

                          {/* Previous crop */}
                          {GenerateFields(
                            'Previous Crop',
                            'previous_crop',
                            3,
                            index,
                            errors,
                            touched
                          )}

                          {/* Next Crop */}
                          <Col className='' md={props.home ? 12 : 3}>
                            <div className='input-group'>
                              <div className='form-floating'>
                                <Select
                                  id={`samples[${index}].next_crop`}
                                  defaultValue={values.next_crop}
                                  name={`samples[${index}].next_crop`}
                                  className={`custom-dropdown form-group basic-single group rounded z-3 text-black ${
                                    errors.samples?.[index]?.next_crop &&
                                    touched.samples?.[index]?.next_crop
                                      ? 'border-danger border-2'
                                      : touched.samples?.[index]?.next_crop
                                      ? 'border-success border-2'
                                      : ''
                                  }`}
                                  isSearchable={true}
                                  placeholder='Next Crop'
                                  styles={customStyles} // Apply custom styles
                                  options={[
                                    {
                                      value: 'wheat ( गेहूँ )',
                                      label: 'wheat ( गेहूँ )',
                                    },
                                    {
                                      value: 'rice ( चावल )',
                                      label: 'rice ( चावल )',
                                    },
                                    {
                                      value: 'sugar cane ( गन्ना )',
                                      label: 'sugar cane ( गन्ना )',
                                    },
                                    {
                                      value: 'cowpea ( लोबिया )',
                                      label: 'cowpea ( लोबिया )',
                                    },
                                    {
                                      value: 'groundnut ( मूंगफली )',
                                      label: 'groundnut ( मूंगफली )',
                                    },
                                    {
                                      value: 'maize ( मक्का )',
                                      label: 'maize ( मक्का )',
                                    },
                                    {
                                      value: 'mustard ( सरसों )',
                                      label: 'mustard ( सरसों )',
                                    },
                                    {
                                      value: 'brinjal ( बैंगन )',
                                      label: 'brinjal ( बैंगन )',
                                    },
                                    {
                                      value: 'radish ( मूली )',
                                      label: 'radish ( मूली )',
                                    },
                                    {
                                      value: 'cauliflower ( फूलगोभी )',
                                      label: 'cauliflower ( फूलगोभी )',
                                    },
                                    {
                                      value: 'chilli ( मिर्च )',
                                      label: 'chilli ( मिर्च )',
                                    },
                                    {
                                      value: 'potato ( आलू )',
                                      label: 'potato ( आलू )',
                                    },
                                    {
                                      value: 'kinnow ( किन्नू )',
                                      label: 'kinnow ( किन्नू )',
                                    },
                                    {
                                      value: 'green chilli ( हरी मिर्च )',
                                      label: 'green chilli ( हरी मिर्च )',
                                    },
                                    {
                                      value: 'guava ( अमरूद )',
                                      label: 'guava ( अमरूद )',
                                    },
                                    {
                                      value: 'marigold ( गेंदे का फूल )',
                                      label: 'marigold ( गेंदे का फूल )',
                                    },
                                  ]}
                                  value={
                                    values.samples[index].next_crop
                                      ? {
                                          value:
                                            values.samples[index].next_crop,
                                          label:
                                            values.samples[index].next_crop,
                                        }
                                      : null
                                  }
                                  onChange={(option) =>
                                    setFieldValue(
                                      `samples[${index}].next_crop`,
                                      option.value
                                    )
                                  }
                                />
                                <ErrorMessage
                                  className='form_errors'
                                  name={`samples[${index}].next_crop`}
                                  component='div'
                                />
                              </div>
                            </div>
                          </Col>

                          {GenerateFields(
                            'Comments',
                            'comments',
                            6,
                            index,
                            errors,
                            touched
                          )}
                        </Row>
                      </div>
                    ))}

                    {sessionStorage.clear()}
                    {props.home ? (
                      <>
                        <p>
                          Have multiples Samples?
                          <Link
                            to={{ pathname: '/bookingform' }}
                            onClick={() => {
                              sessionStorage.setItem(
                                'bookingData',
                                JSON.stringify(values.samples)
                              );
                            }}
                          >
                            {' '}
                            Click Here{' '}
                          </Link>
                        </p>
                        <button
                          type='submit'
                          className='btn btn-dark w-100 text-center'
                        >
                          Submit
                          <HiChevronRight style={{ marginLeft: '20px' }} />
                        </button>
                      </>
                    ) : (
                      <div
                        className={`d-flex ${
                          arrayHelpers.form.values.samples.length > 1
                            ? 'flex-column'
                            : ''
                        } flex-md-row justify-content-between`}
                      >
                        <div className='d-flex flex-md-row justify-content-between mb-md-0 mb-2'>
                          <button
                            type='button'
                            className='btn btn-dark btn-block'
                            onClick={() => arrayHelpers.push(sampleDetails)}
                          >
                            Add Sample
                          </button>
                          &nbsp; &nbsp;
                          {arrayHelpers.form.values.samples.length > 1 && (
                            <button
                              type='button'
                              className='btn btn-dark btn-block'
                              onClick={() => arrayHelpers.pop()}
                            >
                              Remove Last Sample
                            </button>
                          )}
                        </div>
                        <button
                          type='submit'
                          className='w-md-50 justify-content-center btn btn-dark'
                        >
                          Submit
                          <HiChevronRight style={{ marginLeft: '20px' }} />
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </FieldArray>

              <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
              >
                <Modal.Header>
                  <Modal.Title>
                    <img
                      className=''
                      alt='logo'
                      src={SoilDoctorLogo}
                      width='200px'
                    />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Thank you for submitting the request ! <br></br>
                  Our representative will contact you within next 24 hours.
                  <br></br>
                  <br></br>
                  Please take a note of the Sample IDs for future reference -{' '}
                  <br></br>
                  <br></br>
                  {bookingSummary.map((sample, index) => (
                    <div
                      key={index}
                      className={`${
                        (index + 1) % 1 == 0 ? 'bg-light' : ''
                      } p-4 mb-3 rounded-3`}
                    >
                      <Row>
                        <Col md={12} className='mb-3'>
                          <div className='form-control h-100 d-flex align-items-center justify-content-center bg-primary text-white'>
                            S{index + 1}
                          </div>
                        </Col>
                        <Col md={5} xs={5}>
                          {' '}
                          <p> Sample Id</p>{' '}
                        </Col>
                        <Col md={7} xs={7}>
                          {' '}
                          <p>: &emsp; {sample.sample_id} </p>{' '}
                        </Col>

                        <Col md={5} xs={5}>
                          {' '}
                          <p> Farm Name </p>{' '}
                        </Col>
                        <Col md={7} xs={7}>
                          {' '}
                          <p>: &emsp; {sample.farm_name} </p>{' '}
                        </Col>

                        <Col md={5} xs={5}>
                          {' '}
                          <p> Farm Size </p>{' '}
                        </Col>
                        <Col md={7} xs={7}>
                          {' '}
                          <p>
                            : &emsp; {sample.farm_size} {sample.farm_size_unit}{' '}
                          </p>{' '}
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <input
                    type='checkbox'
                    name='confirm-box'
                    checked={isChecked}
                    className='form-check-input'
                    onChange={() => setIsChecked(!isChecked)}
                  />
                  &ensp; I have noted down the required details.
                  <br></br>
                  <br></br>
                </Modal.Body>
                <Modal.Footer>
                  <button onClick={handleClose} disabled={!isChecked}>
                    {' '}
                    Close{' '}
                  </button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>
      </Row>
    </div>
  );
}

export default BookingFormikForm;
