import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserContext from './UserContext';
import './App.css';

import Auth from './Auth';
import StepForm from './components/Common/Authentication/stepform';

//User Routes
import UserProfile from './components/User/Profile/Profile';
import UserHome from './components/User/Home/home';
import ReportForm from './components/User/Form/ReportForm';
import BookingForm from './components/User/Form/BookingForm';
import EditReport from './components/User/Form/EditReport';
import Report from './components/User/Report/Reports';
import Booking from './components/User/Booking/Bookings';
import ViewReport from './components/User/Report/ViewReport';
import SampleTest from './components/User/SampleTesting/TestingSteps';
import Navbar from './components/User/Layout/Navbar';
import Layout from './components/User/Layout/Layout';
import FollowUpForm from './components/User/Booking/FollowUpForm';
import ContactForm from './components/User/Contact/ContactForm';

//Admin Routes
import AdminHome from './components/Admin/Home/Home';
import Reports from './components/Admin/Reports/Reports';
import GenerateReport from './components/Admin/GenerateReport/GenerateReport';
import Bookings from './components/Admin/Bookings/Bookings';
import BookTest from './components/Admin/BookTest/BookTest';
import DetailedReport from './components/Admin/Reports/DetailedReport';
import AdminEditReport from './components/Admin/Reports/EditReport';
import NotFound from './components/Common/NotFound';

function App() {
  var user = null;

  if (Auth.isAuthenticated()) {
    user = Auth.getUserData();
    if (user) {
      return (
        <Router>
          <UserContext.Provider value={user}>
            <Routes>
              <Route path='/' element={<Layout />}>
                <Route path='/' Component={UserHome} />
                <Route path='/profile' Component={UserProfile} />
                <Route path='/contact' Component={ContactForm} />
                <Route path='/form' Component={ReportForm} />
                <Route path='/bookingform' Component={BookingForm} />
                <Route path='/editreport' element={<EditReport />} />
                <Route path='/reports' Component={Report} />
                <Route path='/bookings' Component={Booking} />
                <Route path='/report' Component={ViewReport} />
                <Route path='/sampletest' Component={SampleTest} />
                <Route path='/FollowUpForm' Component={FollowUpForm} />
                <Route path='*' element={<NotFound />} />
              </Route>
            </Routes>
          </UserContext.Provider>
        </Router>
      );
    }
    // else if (user?.role === "ADMIN") {
    //   return (
    //     <Router>
    //       <UserContext.Provider value={user}>
    //         <Routes>
    //           <Route path="/" element={<AdminHome />} />
    //           <Route path="/admin/reports" element={<Reports />} />
    //           <Route path="/admin/detailedreport" element={<DetailedReport />} />
    //           <Route path="/admin/editreport" element={<AdminEditReport />} />
    //           <Route path="/admin/generatereport" element={<GenerateReport />} />
    //           <Route path="/admin/booktest" element={<BookTest />} />
    //           <Route path="/admin/bookings" element={<Bookings />} />
    //         </Routes>
    //       </UserContext.Provider>
    //     </Router>
    //   )
    // }
  } else
    return (
      <Router>
        <StepForm />
      </Router>
    );
}

export default App;
