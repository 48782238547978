import React, { useState } from 'react';
import ConnectDevice from './ConnectDevice';
import SelectSample from './SelectSample';
import SampleTest from './SampleTest';

const TestingSteps = () => {
	const [ state, setState ] = useState({
		device_id:'',
		booking_id: '',
		samples_count: ''
	});
	

	const [value, setValue] = useState({
		device_id:'EKO1332JJ23',
		booking_id: '',
		samples_count: '',
		samples:[],
		sampleParameters: []
	});

	const [step, setStep] = useState(2)
	
	const handleChange = (input) => (e) => {
		setState({...state, [input]: e.target.value });
	};

	const nextStep = () => {       
		setStep(prevStep => prevStep + 1)
	};

	const prevStep = () => {        
		setStep(prevStep => prevStep - 1)
	};

	const setValues = (e) => {
		console.log("setValues", e);
		setValue(e);
	}



	// hashHandleChange={hashHandleChange} 

	switch (step) {
		case 1:
			return <ConnectDevice nextStep={nextStep} handleChange={handleChange} value={value} setValues={setValues}/>;
		case 2: 
			return <SelectSample nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} value={value} setValues={setValues}/>;
        case 3:
			return <SampleTest nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} value={value} />;
        default:
            return <ConnectDevice nextStep={nextStep} handleChange={handleChange} value={value} />  
            
    }
};
  export default TestingSteps;